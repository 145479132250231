import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-icon-button',
  templateUrl: './norby-icon-button.component.html',
  styleUrls: ['./norby-icon-button.component.less']
})
export class NorbyIconButtonComponent {
  @Input() iconName: string;
  @Input() commandName: string;
  @Input() isDisabled: boolean;
  @Input() isLoading?: boolean;
  @Input() buttonType?: 'bordered' | 'default' = 'default';

  @Output() onButtonClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  handleButtonClick(event: Event): void {
    this.onButtonClicked.emit(this.commandName || this.iconName);
  }
}
