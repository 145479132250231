import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { HeaderStyle } from 'models';

@Component({
  standalone: false,
  selector: 'norby-header-style',
  templateUrl: './norby-header-style.component.html',
  styleUrls: ['./norby-header-style.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyHeaderStyleComponent),
      multi: true
    }
  ]
})
export class NorbyHeaderStyleComponent
  implements ControlValueAccessor, OnChanges
{
  @Input() value: HeaderStyle = 'brand';
  @Input() maskUrl?: string = 'assets/svg/shapes/burst.svg';
  @Input() imageUrl?: string = 'assets/svg/norby.svg';
  @Input() width?: string = '80px';
  @Input() height?: string = '80px';
  @Input() disabled?: boolean = false;

  val: HeaderStyle;

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  constructor() {}

  ngOnInit(): void {
    this.val = this.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.val = this.value;
    }
  }

  writeValue(value: HeaderStyle) {
    this.val = value;
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  handleStyleSelection(style: HeaderStyle): void {
    this.val = style;
    this._onChanged(style);
    this._markAsTouched();
  }
}
