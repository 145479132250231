import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ElementRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { FORM_ERROR_PROVIDER } from 'models';
import { BaseComponent } from '../../../models';

@Component({
  standalone: false,
  selector: 'norby-input',
  templateUrl: './norby-input.component.html',
  styleUrls: ['./norby-input.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NorbyInputComponent
  extends BaseComponent
  implements ControlValueAccessor, OnChanges, AfterViewInit, OnDestroy
{
  @ViewChild('input') inputEl: ElementRef;

  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() helperText?: string;
  @Input() infoTooltip?: string;
  @Input() maxLength?: number;
  @Input() value?: string;
  @Input() type?: 'text' | 'number' | 'email' | 'tel' = 'text';
  @Input() isDisabled?: boolean = false;
  @Input() isRequired?: boolean = false;
  @Input() isReadOnly?: boolean = false;
  @Input() isNestedButton?: boolean = false;
  @Input() isJoinedRight?: boolean = false;
  @Input() isJoinedLeft?: boolean = false;
  @Input() isUrl?: boolean = false;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() iconName?: string;
  @Input() isLoading?: boolean = false;
  @Input() isTextHidden?: boolean = false; // Used to display placeholder instead of text

  @Output() onBlur: EventEmitter<string> = new EventEmitter<string>();
  @Output() onEnter: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() onFocus: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class')
  elementClass = 'flex-1';

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};

  val: string;
  errorText: string = '';
  touched = false;

  get hasErrors(): boolean {
    return !!this.errorText;
  }

  constructor(
    @Optional() @Self() private _ngControl: NgControl,
    @Optional() @Inject(FORM_ERROR_PROVIDER) private _errorFactory,
    private _cdr: ChangeDetectorRef
  ) {
    super();
    if (_ngControl) {
      _ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.val = this.value;
      this._updateErrors();
    }
  }

  ngAfterViewInit(): void {
    if (
      this._ngControl &&
      this._ngControl.value &&
      this._ngControl.value !== this.val
    ) {
      this.writeValue(this._ngControl.value);
    }
  }

  focus() {
    this.inputEl?.nativeElement?.focus();
  }

  handleInput(target) {
    this.val = target.value;
    this._onChanged(target.value);
    this._markAsTouched();
    this._updateErrors();
    this._cdr.detectChanges();
  }

  writeValue(value: string) {
    this.val = value;
    this._updateErrors();
    this._cdr.detectChanges();
  }

  registerOnChange(fn: any) {
    this._onChanged = (value?: string) => {
      const prefixes = [
        '',
        'h',
        'ht',
        'htt',
        'http',
        'https',
        'https:',
        'https:/'
      ];

      if (
        !this.isUrl ||
        prefixes.includes(value) ||
        value.startsWith('https://') ||
        value.startsWith('http://')
      ) {
        fn(value);
      } else {
        fn(`https://${value}`);
      }
    };
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _updateErrors() {
    const controlErrors = this._ngControl?.errors ?? {};
    const errorKeys = Object.keys(controlErrors);
    if (errorKeys.length && this.touched) {
      const errorKey = errorKeys[0];
      const getErrorMessage = this._errorFactory?.[errorKey];
      this.errorText = getErrorMessage?.(controlErrors[errorKey]) ?? 'Error';
    } else if (this.hasErrors) {
      this.errorText = '';
    }
  }

  private _markAsTouched() {
    if (!this.touched) {
      this.touched = true;
      this._onTouched();
    }
  }

  handleOnBlur(event) {
    this.onBlur.emit(event?.target?.value);
  }

  handleOnEnter(event) {
    this.onEnter.emit(event);
  }

  handleOnFocus() {
    this.onFocus.emit();
  }
}
