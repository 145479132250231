import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-formcontrol-label',
  templateUrl: './norby-formcontrol-label.component.html',
  styleUrls: ['./norby-formcontrol-label.component.less']
})
export class NorbyFormcontrolLabelComponent {
  @Input() label?: string;
  @Input() infoTooltip?: string;
  @Input() isRequired?: boolean = false;

  constructor() {}
}
