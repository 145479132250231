import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-toggle-buttons',
  templateUrl: './norby-toggle-buttons.component.html',
  styleUrls: ['./norby-toggle-buttons.component.less']
})
export class NorbyToggleButtonsComponent {
  @Input() iconNames: string[];
  @Input() selectedIndex?: number = 0;
  @Input() disabled?: boolean = false;

  @Output() onButtonClicked: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  handleSelection(index: number): void {
    this.onButtonClicked.emit(index);
  }
}
