import { Component, Inject, OnInit, Optional } from '@angular/core';

// App
import { BaseComponent, MODAL_DATA, ModalConfig, ModalRef } from 'uikit';
import { ContentRegisterable, IUserContent, ApiSurfaces } from 'models';
import { UserService, ApiService, AuthService } from '../../services';

@Component({
  standalone: false,
  selector: 'lib-rsvp-confirmation',
  templateUrl: './rsvp-confirmation.component.html',
  styleUrls: ['./rsvp-confirmation.component.less']
})
export class RsvpConfirmationComponent extends BaseComponent implements OnInit {
  content: ContentRegisterable;
  userContent: IUserContent;
  themeClasses: string[];
  hasLoaded = false;

  constructor(
    @Optional() @Inject(MODAL_DATA) public config: ModalConfig,
    private _api: ApiService,
    private _user: UserService,
    private _auth: AuthService,
    private _dialogRef: ModalRef
  ) {
    super();
    if (!!config) {
      this.content = config.content as ContentRegisterable;
      this.userContent = config.userContent;
      this.themeClasses = config.themeClasses;
    }
  }

  ngOnInit(): void {
    this._user
      .getUserContent$(this.content?.contentId)
      .pipe(this.takeUntilDestroy)
      .subscribe(async (userContent) => {
        this.userContent = userContent;
        if (!userContent?.shortLinks?.referralPageShortLink)
          await this._callReferralEndpoint(userContent.contentId);
        this.hasLoaded = true;
      });
  }

  private async _callReferralEndpoint(contentId: string) {
    const endpoint = `content/${contentId}/referral`;
    const result = await this._api.post<IUserContent>(
      ApiSurfaces.END_USER,
      endpoint,
      {}
    );

    if (result) {
      this.userContent = result;
    }
  }

  handleSubmitAnother() {
    this._auth.logout();
    this._dialogRef.close();
  }
}
