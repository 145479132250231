import { Component, Input } from '@angular/core';
import { fadeInOut } from '../../animations';
import { IFloatingActionButton } from 'models';

@Component({
  standalone: false,
  selector: 'lib-floating-action-buttons-view',
  templateUrl: './floating-action-buttons-view.component.html',
  styleUrls: ['./floating-action-buttons-view.component.less'],
  animations: [fadeInOut]
})
export class FloatingActionButtonsViewComponent {
  @Input() visible = false;
  @Input() message: string;
  @Input() messageState: 'warning' | 'error' | 'success' | 'info' | 'none' =
    'none';
  @Input() buttons: IFloatingActionButton[];
  @Input() disabled: false;
  @Input() loading: false;
}
