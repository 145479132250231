import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  QueryList
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseComponent } from '../../../models/base-component';
import { NorbyImageRadioButtonComponent } from '../norby-image-radio-button/norby-image-radio-button.component';

@Component({
  standalone: false,
  selector: 'norby-image-radio-button-group',
  templateUrl: './norby-image-radio-button-group.component.html',
  styleUrls: ['./norby-image-radio-button-group.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyImageRadioButtonGroupComponent),
      multi: true
    }
  ]
})
export class NorbyImageRadioButtonGroupComponent
  extends BaseComponent
  implements ControlValueAccessor, AfterContentInit, OnChanges
{
  @Input() value?: string;
  @Input() disabled?: boolean = false;

  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  @ContentChildren(NorbyImageRadioButtonComponent) buttons:
    | QueryList<NorbyImageRadioButtonComponent>
    | undefined;

  val: string;

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  constructor() {
    super();
  }

  ngOnChanges(): void {
    if (this.value) {
      this.writeValue(this.value);
    }
  }

  ngAfterContentInit(): void {
    if (this.buttons) {
      let buttonsArray = this.buttons.toArray();
      for (let i = 0; i < buttonsArray.length; i++) {
        buttonsArray[i].nextButtonValue = buttonsArray[i + 1]?.buttonValue;
        buttonsArray[i].selectedValue = this.val;
        buttonsArray[i].buttonClicked
          .pipe(this.takeUntilDestroy)
          .subscribe((buttonValue) => {
            this._updateVal(buttonValue);
            this.buttonClicked.emit(buttonValue);
            this._onChanged(buttonValue);
            this._markAsTouched();
          });
      }
    }
  }

  private _updateVal(value: string) {
    this.val = value;
    if (this.buttons && this.val) {
      this.buttons.forEach((button) => {
        button.selectedValue = this.val;
      });
    }
  }

  writeValue(value: string) {
    this._updateVal(value);
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }
}
