import { Component, Input } from '@angular/core';
import { IImage, IResponsiveImage, ImagePageBlock } from 'models';

@Component({
  standalone: false,
  selector: 'lib-image-block-view',
  templateUrl: './image-block-view.component.html',
  styleUrls: ['./image-block-view.component.less']
})
export class ImageBlockViewComponent {
  @Input() imageBlock: ImagePageBlock;

  get imageUrl(): string {
    return this.imageBlock?.images.length > 0
      ? this.imageBlock?.images[0]?.url
      : '';
  }

  get image(): IImage {
    return this.imageBlock?.images.length > 0
      ? this.imageBlock?.images[0]
      : null;
  }

  get imgixImage(): IResponsiveImage {
    return this.imageBlock?.responsiveImages?.length > 0
      ? this.imageBlock?.responsiveImages[0]
      : null;
  }

  get altText(): string {
    return this.imageBlock?.images[0].altText || '';
  }
}
