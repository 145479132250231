import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import dayjs from 'dayjs';

// Libs
import {
  ContentClick,
  ContentSignup,
  IPromptResponse,
  IUserContent,
  SignupType,
  PromptResponseChanges
} from 'models';
import { rootLock, rootCalendar, rootUnlock } from '../../../icons';
import { IconService, MockDeviceService } from '../../services';

@Component({
  standalone: false,
  selector: 'lib-drop-card-view',
  templateUrl: './drop-card-view.component.html',
  styleUrls: ['./drop-card-view.component.less']
})
export class DropCardViewComponent implements OnChanges {
  @Output() cardClick = new EventEmitter<ContentClick>();
  @Input() drop: ContentSignup;
  @Input() userContent: IUserContent;
  @Input() isLoadingDrop = false;
  @Input() isLoadingUserContent = false;
  @Input() isDisabled = false;
  @Input() overrideTitle: string;
  @Input() overrideSignupType: SignupType;

  registrationCloseDate: string;
  registrationCloseTime: string;
  stagedPromptResponses: IPromptResponse[];
  isFormInvalid: boolean = false;

  constructor(
    private _device: MockDeviceService,
    private _iconService: IconService
  ) {
    this._iconService.registerIcons([rootLock, rootCalendar, rootUnlock]);
  }

  ngOnChanges(): void {
    this.registrationCloseDate = this.drop?.registrationCloseDate
      ? dayjs(this.drop.registrationCloseDate).format('MMM DD, YYYY')
      : null;
    this.registrationCloseTime = this.drop?.registrationCloseDate
      ? dayjs(this.drop.registrationCloseDate).format('h:mm A')
      : null;
  }

  isCurrentDate(registrationCloseDate: string): boolean {
    return registrationCloseDate === dayjs().format('MMM DD, YYYY');
  }

  get dateTimePrompt(): string {
    return this.drop?.tickets?.length > 0 ? 'Purchase by' : 'Sign up by';
  }

  handleClick(event): void {
    if (this.isDisabled) {
      event.preventDefault();
    } else {
      this.cardClick.next({ content: this.drop, event });
    }
  }

  handlePromptResponseChanges(
    promptResponseChanges: PromptResponseChanges
  ): void {
    this.stagedPromptResponses = promptResponseChanges?.promptResponses;
    this.isFormInvalid = !promptResponseChanges?.valid;
  }

  get title(): string {
    return this.overrideTitle || this.drop?.title;
  }

  get signupType(): SignupType {
    return this.drop
      ? this.overrideSignupType || this.drop.signupType || 'flow'
      : null;
  }

  get priceStr(): string {
    if (this.drop?.tickets?.length > 0) {
      const price = this.drop.tickets[0].price / 100;
      return `From $${price}`;
    }

    return null;
  }

  get href(): string {
    return `https://${this._device.currentSiteRootURL}/signup/${this.drop?.contentId}`;
  }
}
