import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-footer',
  templateUrl: './norby-footer.component.html',
  styleUrls: ['./norby-footer.component.less']
})
export class NorbyFooterComponent {
  @Input() activeIndex: number;
  @Input() firstStepIndex: number = 0;
  @Input() isDisabled: boolean = false;
  @Input() isMobile: boolean = false;
  @Input() steps: string[];

  @Output() onNavigationButtonClicked = new EventEmitter<number>();

  constructor() {}

  handlePreviousButtonClicked(): void {
    this.onNavigationButtonClicked.emit(this.activeIndex - 1);
  }

  handleAdvanceButtonClicked(): void {
    this.onNavigationButtonClicked.emit(this.activeIndex + 1);
  }

  handleMenuItemClicked(index): void {
    this.onNavigationButtonClicked.emit(index);
  }
}
