import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  standalone: false,
  selector: 'norby-select',
  templateUrl: './norby-select.component.html',
  styleUrls: ['./norby-select.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbySelectComponent),
      multi: true
    }
  ]
})
export class NorbySelectComponent implements ControlValueAccessor, OnChanges {
  @Input() label?: string;
  @Input() helperText?: string;
  @Input() infoTooltip?: string;
  @Input() isDisabled?: boolean = false;
  @Input() isRequired?: boolean = false;
  @Input() value?: any;
  @Input() prompt?: string;
  @Input() isJoinedRight?: boolean = false;
  @Input() isJoinedLeft?: boolean = false;
  @Input() showError?: boolean = false;
  @Input() size: 'small' | 'medium' = 'medium';

  @Output() onHandleChange: EventEmitter<any> = new EventEmitter<any>();

  val: any;

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.val = this.value;
    }
  }

  writeValue(value: any) {
    this.val = value;
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  get isPlaceholderSelected() {
    return this.value !== undefined && !this.value;
  }

  handleSelectChange(event) {
    const value = event.target.value;
    if (value !== this.val) {
      this.val = value;
      this.onHandleChange.emit(value);
      this._onChanged(value);
      this._markAsTouched();
    }
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }
}
