import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';

import { plainToClass } from 'class-transformer';
import { filter, map } from 'rxjs';

import {
  ImageSingleSendBlock,
  ISlug,
  DEFAULT_BLOCK_PADDING,
  DEFAULT_IMAGE_ALIGNMENT,
  IImage
} from 'models';
import { BaseComponent } from 'uikit';

@Component({
  standalone: false,
  selector: 'edit-email-image-block',
  templateUrl: './edit-email-image-block.component.html',
  styleUrls: ['./edit-email-image-block.component.less']
})
export class EditEmailImageBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() block: ImageSingleSendBlock;
  @Input() slug: ISlug;

  @Output() onUpdatedBlock: EventEmitter<ImageSingleSendBlock> =
    new EventEmitter<ImageSingleSendBlock>();
  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  get blockImageArray(): IImage[] {
    return this.block.image && Object.keys(this.block.image).length > 0
      ? [this.block?.image]
      : [];
  }

  get clickThroughUrlDefault(): string {
    // TODO replace with `https://${this.slug?.defaultDomain}` pending change to nor.by
    return `https://${this.slug?.slug}.nor.by`;
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        image: this.block?.image,
        height: this.block?.height,
        clickThroughUrl: this.block?.clickThroughUrl,
        alignment: this.block?.alignment,
        paddingTop: this.block?.paddingTop,
        paddingRight: this.block?.paddingRight,
        paddingBottom: this.block?.paddingBottom,
        paddingLeft: this.block?.paddingLeft
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      image: [this.block.image ?? null],
      height: [this.block.height ?? null],
      clickThroughUrl: [this.block.clickThroughUrl ?? ''],
      alignment: [this.block.alignment ?? DEFAULT_IMAGE_ALIGNMENT],
      paddingTop: [
        this.block?.paddingTop ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingRight: [
        this.block?.paddingRight ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingBottom: [
        this.block?.paddingBottom ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingLeft: [
        this.block?.paddingLeft ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) =>
          plainToClass(ImageSingleSendBlock, {
            ...this.block,
            ...value
          })
        ),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }

  handleBackgroundImageUpdated(image: IImage[]) {
    this.formGroup.patchValue({
      image: image[0]
    });
  }
}
