import {
  Component,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

import { BaseComponent } from 'uikit';
import { IUserContent, ContentEvent } from 'models';
import { UserService } from '../../services';

/*
  Creates a main button (register/rsvp/purchase) for a given event
  Manages its own subscription to user content
*/

@Component({
  standalone: false,
  selector: 'lib-event-main-button',
  templateUrl: './event-main-button.component.html',
  styleUrls: ['./event-main-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventMainButtonComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() event: ContentEvent;
  userEvent: IUserContent;
  hasLoaded = false;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _user: UserService
  ) {
    super();
  }

  ngOnChanges(): void {
    super.ngOnChanges();

    if (this.event?.contentId)
      this._user
        .getUserContent$(this.event.contentId)
        .pipe(this.takeUntilChanges)
        .subscribe((e) => {
          this.userEvent = e;
          this.hasLoaded = true;
          this._cdr.detectChanges();
        });
  }
}
