import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit
} from '@angular/core';

import { IconService } from '../../services/icon';
import { rootLoader } from '../../../icons/build';

@Component({
  standalone: false,
  selector: 'root-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.less']
})
export class ButtonComponent implements AfterViewInit {
  @Input() type?: 'primary' | 'secondary' | 'ghost';
  @Input() size?: 'default' | 'large' | 'small';
  @Input() block?: boolean;
  @Input() isOnCard?: boolean;
  @Input() loading?: boolean;
  @Input() text?: string;
  @Input() name?: string;
  @Input() ariaLabel?: string;
  @Input() radioLeft?: boolean;
  @Input() radioRight?: boolean;
  @Input() disabled?: boolean;
  @Input() formSubmit?: 'button';
  @Input() adjacentInput?: boolean;
  @Output() handleClick = new EventEmitter();
  isIconOnly: boolean;

  constructor(
    private _elementRef: ElementRef,
    private _iconService: IconService
  ) {
    this._iconService.registerIcons([rootLoader]);
  }

  assertIconOnly(element: HTMLDivElement): void {
    const listOfNode = Array.from(
      element.firstChild.firstChild.lastChild.childNodes
    );
    const iconCount = listOfNode.filter(
      (node) => node.nodeName === 'ROOT-ICON'
    ).length;
    const noText = listOfNode.every((node) => node.nodeName !== '#text');
    const noSpan = listOfNode.every((node) => node.nodeName !== 'SPAN');
    this.isIconOnly = noSpan && noText && !this.text && iconCount >= 1;
  }

  ngAfterViewInit(): void {
    this.assertIconOnly(this._elementRef.nativeElement);
  }
}
