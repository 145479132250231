import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-icon-radio-button',
  templateUrl: './norby-icon-radio-button.component.html',
  styleUrls: ['./norby-icon-radio-button.component.less']
})
export class NorbyIconRadioButtonComponent {
  @Input() iconName: string;
  @Input() buttonLabel: string;
  @Input() buttonValue: string;
  @Input() selectedValue?: string;
  @Input() isDisabled?: boolean;
  @Input() isFirst?: boolean;
  @Input() isLast?: boolean;
  @Input() nextButtonValue?: string;
  @Input() size: 'default' | 'large' | 'small' = 'default';

  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  handleButtonClick(): void {
    this.buttonClicked.emit(this.buttonValue);
  }
}
