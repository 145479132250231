import {
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Directive,
  HostBinding,
  Inject,
  SkipSelf,
  Optional
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { customAlphabet } from 'nanoid';

// Lib
import { Theme, compareThemes } from 'models';
import { LiveThemedService } from '../services/live-themed';
import { THEME_CLASS, THEME_CLASSES } from '../constants';
import { BaseComponent } from '../models';

/*
  A directive that turns Theme objects into encapsulated styles and
  manages the associated CSS style node in the DOM.

  `live-themed` can be used to stack themes at multiple levels in the DOM
  hierarchy. For instance, each of the following has a Theme property:

  - Account (Slug)
  - Page (LandingPage)
  - Block (PageBlock)

  You can easily apply those themes styles with the generated CSS gracefully
  falling back from most-to-least specific:

  ```
    <div live-themed [theme]="slugTheme">
      <lib-page live-themed [theme]="pageTheme">
        <lib-block *ngFor="let block of blocks" live-themed [theme]="block.theme">
        </lib-block>
      </lib-page>
    </div>
  ```

  If `applyThemeStyles` is true, the node will act as a live preview of
  whatever styles are passed in with the Theme before falling back to parent
  and ancestor theme styles.
*/

// Only letters and underscores are allowed as the first letter of a
// CSS class
const firstCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_';

// Numbers and hyphens are allowed to appear after the first character
// in a CSS class name
const classCharacters = `${firstCharacters}1234567890-`;

// Generate the first character of a random CSS class
const generateFirstCharacter = () =>
  firstCharacters[Math.floor(Math.random() * firstCharacters.length)];

// Generate a random CSS class name after the first letter
const generateClassIdentifier = () =>
  `${generateFirstCharacter()}${customAlphabet(classCharacters, 7)()}`;

// Allows us to provide the class names of every `live-themed` ancestor
const concatClassIdentifiers = (className: string, classNameArray: string[]) =>
  classNameArray ? [className, ...classNameArray] : [className];

// Provide the class name of the closest `live-themed` parent
const ThemeClassProvider = {
  provide: THEME_CLASS,
  useFactory: generateClassIdentifier
};

// Provide the class names of every `live-themed` ancestor
const ThemeClassesProvider = {
  provide: THEME_CLASSES,
  useFactory: concatClassIdentifiers,
  deps: [THEME_CLASS, [new SkipSelf(), new Optional(), THEME_CLASSES]]
};

@Directive({
  standalone: false,
  selector: '[live-themed]',
  providers: [ThemeClassProvider, ThemeClassesProvider]
})
export class LiveThemedDirective
  extends BaseComponent
  implements OnChanges, OnDestroy
{
  @Input() theme: Theme;
  @Input() applyThemeStyles = true;

  @HostBinding('class')
  get wrapperClass() {
    return this._wrapperClass;
  }

  private _currentStyleNode: HTMLStyleElement;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(THEME_CLASS) private _wrapperClass: string,
    @Inject(THEME_CLASSES) private _wrapperClasses: string[],
    private _themeService: LiveThemedService
  ) {
    super();
  }

  ngOnChanges(changes?: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (!this.applyThemeStyles) {
      this._removeStyleNode();
    } else if (!compareThemes(changes.theme?.previousValue, this.theme)) {
      this._updateCss();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._removeStyleNode();
  }

  private get _styleNode(): HTMLStyleElement {
    if (!this._currentStyleNode) {
      this._currentStyleNode = this._document.createElement('style');
      this._currentStyleNode.setAttribute('data-theme-id', this._wrapperClass);
      this._documentHead.appendChild(this._currentStyleNode);
    }

    return this._currentStyleNode;
  }

  private get _documentHead() {
    return (
      this._document.head || this._document.getElementsByTagName('head')[0]
    );
  }

  private _removeStyleNode() {
    if (!this._currentStyleNode) {
      return;
    }

    // Remove any previously generated styles
    this._documentHead.removeChild(this._currentStyleNode);
    this._currentStyleNode = null;
    const selector = this._wrapperClasses
      .reduce((a, c) => `.${c} ${a}`, '')
      .trim();
    this._themeService.removeCachedTheme(selector);
  }

  private _updateCss() {
    if (!this.theme || !this._document || !this._documentHead) {
      return;
    }

    let newCss = '';

    try {
      const selector = this._wrapperClasses
        .reduce((a, c) => `.${c} ${a}`, '')
        .trim();
      newCss = this._themeService.generateCSSFromTheme(this.theme, selector);
      this._themeService.updateFontImportsAndDefinitions(this.theme);
    } catch (e) {}

    const newChild = this._document.createTextNode(newCss);
    this._styleNode.appendChild(newChild);

    while (
      this._styleNode.firstChild &&
      this._styleNode.firstChild !== newChild
    ) {
      this._styleNode.removeChild(this._styleNode.firstChild);
    }
  }
}
