import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';

// 3rd party
import { fromEvent } from 'rxjs';
import { BaseComponent } from '../../models/base-component';

// Lib
import { CheckboxWrapperComponent } from './checkbox-wrapper.component';

export type OnTouchedType = () => any;
export type OnChangeType = (value: any) => void;

@Component({
  standalone: false,
  selector: '[root-checkbox]',
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent
  extends BaseComponent
  implements OnInit, ControlValueAccessor, OnDestroy, AfterViewInit
{
  onChange: OnChangeType = () => {};
  onTouched: OnTouchedType = () => {};

  @ViewChild('inputElement', { static: true })
  inputElement!: ElementRef<HTMLInputElement>;

  @Output() readonly checkedChange = new EventEmitter<boolean>();
  @Input() value: any | null = null;
  @Input() autoFocus: boolean = false;
  @Input() disabled: boolean = false;
  @Input() checked: boolean = false;
  @Input() id: string | null = null;
  @Input() radio: boolean = false;
  @Input() size: 'small' | 'default' = 'default';

  @HostBinding('class')
  elementClass = 'inline-flex items-center cursor-pointer';

  constructor(
    private _ngZone: NgZone,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() private _checkboxWrapperComponent: CheckboxWrapperComponent,
    private _cdr: ChangeDetectorRef,
    private _focusMonitor: FocusMonitor
  ) {
    super();
  }

  ngOnInit(): void {
    if (this._checkboxWrapperComponent) {
      this._checkboxWrapperComponent.addCheckbox(this);
    }

    this._ngZone.runOutsideAngular(() => {
      fromEvent(this._elementRef.nativeElement, 'click')
        .pipe(this.takeUntilDestroy)
        .subscribe((event) => {
          event.preventDefault();
          this.focus();
          if (this.disabled) {
            return;
          }
          this._ngZone.run(() => {
            this.innerCheckedChange(!this.checked);
            this._cdr.markForCheck();
          });
        });

      fromEvent(this.inputElement.nativeElement, 'click')
        .pipe(this.takeUntilDestroy)
        .subscribe((event) => event.stopPropagation());
    });
  }

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      this.focus();
    }
  }

  ngOnDestroy(): void {
    if (this._checkboxWrapperComponent) {
      this._checkboxWrapperComponent.removeCheckbox(this);
    }
    super.ngOnDestroy();
  }

  innerCheckedChange(checked: boolean): void {
    if (!this.disabled) {
      this.checked = checked;
      this.onChange(this.checked);
      this.checkedChange.emit(this.checked);
      if (this._checkboxWrapperComponent) {
        this._checkboxWrapperComponent.onChange();
      }
    }
  }

  writeValue(value: boolean): void {
    this.checked = value;
    this._cdr.markForCheck();
  }

  registerOnChange(fn: OnChangeType): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchedType): void {
    this.onTouched = fn;
  }

  focus(): void {
    this._focusMonitor.focusVia(this.inputElement, 'keyboard');
  }

  blur(): void {
    this.inputElement.nativeElement.blur();
  }
}
