import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';

import { plainToClass } from 'class-transformer';
import { filter, map } from 'rxjs';

import {
  DEFAULT_BLOCK_PADDING,
  DEFAULT_IMAGE_ALIGNMENT,
  DEFAULT_BLOCK_FONT_SIZE,
  DEFAULT_BLOCK_ATTRIBUTION_FONT_SIZE,
  ISlug,
  QuoteSingleSendBlock
} from 'models';
import { BaseComponent } from 'uikit';

@Component({
  standalone: false,
  selector: 'edit-email-quote-block',
  templateUrl: './edit-email-quote-block.component.html',
  styleUrls: ['./edit-email-quote-block.component.less']
})
export class EditEmailQuoteBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() block: QuoteSingleSendBlock;
  @Input() slug: ISlug;

  @Output() onUpdatedBlock: EventEmitter<QuoteSingleSendBlock> =
    new EventEmitter<QuoteSingleSendBlock>();
  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        alignment: this.block?.alignment,
        quote: this.block?.quote,
        quoteFontSize: this.block?.quoteFontSize,
        attribution: this.block?.attribution,
        attributionFontSize: this.block?.attributionFontSize,
        paddingTop: this.block?.paddingTop,
        paddingRight: this.block?.paddingRight,
        paddingBottom: this.block?.paddingBottom,
        paddingLeft: this.block?.paddingLeft
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      alignment: [this.block.alignment ?? DEFAULT_IMAGE_ALIGNMENT],
      quote: [this.block.quote ?? ''],
      quoteFontSize: [this.block.quoteFontSize ?? DEFAULT_BLOCK_FONT_SIZE],
      attribution: [this.block.attribution ?? ''],
      attributionFontSize: [
        this.block.attributionFontSize ?? DEFAULT_BLOCK_ATTRIBUTION_FONT_SIZE
      ],
      paddingTop: [
        this.block?.paddingTop ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingRight: [
        this.block?.paddingRight ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingBottom: [
        this.block?.paddingBottom ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingLeft: [
        this.block?.paddingLeft ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) =>
          plainToClass(QuoteSingleSendBlock, {
            ...this.block,
            ...value
          })
        ),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }
}
