import { APP_ID, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// 3rd party
import { QuillModule } from 'ngx-quill';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSpinModule } from 'ng-zorro-antd/spin';

// Firebase
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';

// App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

// Libs
import {
  LoginModule,
  getSharedProviders,
  getAntProviders,
  FirestoreFileUploadService,
  DeviceService,
  ShareService,
  ContentInteractionsService,
  ApiService,
  AnalyticsService,
  ContentManagementService,
  ContentService,
  CustomerContentService,
  AccountStoreService,
  ErrorAlerter
} from 'shared';
import {
  MessageModule,
  MockDeviceService,
  MockFileUploadService,
  MockShareService,
  MockContentInteractionsService,
  MockApiService,
  MockAnalyticsService,
  MockContentManagementService,
  MockContentService
} from 'uikit';
import { MockAccountStoreService } from 'customer-uikit';
import { ANALYTICS_API_URL_TOKEN, ADMIN_NLQ_TOKEN } from 'models';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LoginModule,
    MessageModule.forRoot(),

    // Third party
    QuillModule.forRoot(),
    NzDrawerModule,
    NzModalModule,
    NzNotificationModule,
    NzLayoutModule,
    NzIconModule,
    NzSpinModule,
    NzMenuModule,
    NzButtonModule,
    NzCardModule
  ],
  providers: [
    // Firebase
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),

    // App
    ...getSharedProviders(environment),
    ...getAntProviders(environment),
    { provide: APP_ID, useValue: 'super-admin' },
    { provide: ANALYTICS_API_URL_TOKEN, useValue: environment.analyticsApiUrl },
    { provide: ADMIN_NLQ_TOKEN, useValue: environment.adminNlqUrl },
    {
      provide: MockFileUploadService,
      useClass: FirestoreFileUploadService
    },
    {
      provide: MockDeviceService,
      useExisting: DeviceService
    },
    {
      provide: MockShareService,
      useExisting: ShareService
    },
    {
      provide: MockApiService,
      useExisting: ApiService
    },
    {
      provide: MockAnalyticsService,
      useExisting: AnalyticsService
    },
    {
      provide: MockContentInteractionsService,
      useExisting: ContentInteractionsService
    },
    {
      provide: MockContentService,
      useClass: CustomerContentService
    },
    {
      provide: MockContentManagementService,
      useClass: ContentManagementService
    },
    {
      provide: ContentService,
      useClass: CustomerContentService
    },
    {
      provide: MockAccountStoreService,
      useClass: AccountStoreService
    },
    ...(!environment.production
      ? [
          {
            provide: ErrorHandler,
            useClass: ErrorAlerter
          }
        ]
      : [])
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
