import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { DropdownComponent } from '../root-components/dropdown';
import { POSITION_MAP } from '../constants';

@Directive({ standalone: false, selector: '[sendsVariableSelector]' })
export class SendsVariableSelectorDirective
  implements AfterViewInit, OnDestroy, OnChanges
{
  @Input('sendsVariableSelector') sendsVariableSelector: DropdownComponent;
  @Input() hasSendsVariablesSelector?: boolean = true;
  @Input() isVisible: boolean;
  @Input() position: DOMRect;
  @Input() isMobile: boolean;
  @Input() globalPositionLeft: boolean;
  @Output() onSelectorClosed = new EventEmitter<void>();

  private _overlayRef: OverlayRef;
  private _mouseOnDropdown: boolean = false;
  private _hasListeners: boolean = false;
  private _dropdownInstance: TemplatePortal<any>;
  private _dropdownEnterListener: () => void;
  private _dropdownLeaveListener: () => void;
  private _outsideClickListener: () => void;
  private _addVariableClickListener: () => void;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platform,
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
    private _viewContainerRef: ViewContainerRef,
    private _r2: Renderer2
  ) {}

  ngAfterViewInit(): void {
    if (this.hasSendsVariablesSelector) {
      this._overlayRef = this._overlay.create({
        scrollStrategy: this._overlay.scrollStrategies.reposition()
      });

      this._dropdownInstance = new TemplatePortal(
        this.sendsVariableSelector?.templateRef,
        this._viewContainerRef
      );
    }
  }

  ngOnDestroy() {
    this._dropdownEnterListener?.();
    this._dropdownLeaveListener?.();
    this._outsideClickListener?.();
    this._addVariableClickListener?.();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isVisible && this._overlayRef) {
      if (changes.isVisible.currentValue) {
        this._showOverlay();
      } else {
        this.hide();
      }
    }
  }

  private _getGlobalPositionStrategy(ref: DOMRect) {
    return this.globalPositionLeft
      ? this._overlayPositionBuilder
          .global()
          .right(`${this._document.defaultView.innerWidth - ref.left}px`)
          .top(`${ref.top}px`)
      : this._overlayPositionBuilder
          .global()
          .left(`${ref.left}px`)
          .top(`${ref.top}px`);
  }

  private _getConnectedPositionStrategy(ref: ElementRef) {
    return this._overlayPositionBuilder
      .flexibleConnectedTo(ref)
      .withPositions([
        POSITION_MAP.bottomExtendRight,
        POSITION_MAP.topExtendRight,
        POSITION_MAP.bottomExtendLeft,
        POSITION_MAP.topExtendLeft
      ]);
  }

  private _showOverlay() {
    if (!isPlatformBrowser(this._platform)) {
      return;
    }

    const width = this._elementRef.nativeElement.getBoundingClientRect().width;

    const position = this.isMobile
      ? this._getConnectedPositionStrategy(this._elementRef)
      : this._getGlobalPositionStrategy(this.position);
    this._overlayRef.updatePositionStrategy(position);
    this._overlayRef.updateSize({ minWidth: width / 3 });

    this.show();
  }

  @HostListener('mouseenter')
  mouseEnterHost() {
    if (!this._hasListeners && this.hasSendsVariablesSelector) {
      this._hasListeners = true;
      this._dropdownLeaveListener = this._r2.listen(
        this._overlayRef.overlayElement,
        'mouseleave',
        () => {
          this._mouseOnDropdown = false;
        }
      );

      this._dropdownEnterListener = this._r2.listen(
        this._overlayRef.overlayElement,
        'mouseenter',
        () => {
          this._mouseOnDropdown = true;

          const addButton = this._document.getElementsByClassName(
            'add-variable-button'
          )[0];

          if (addButton) {
            this._addVariableClickListener = this._r2.listen(
              addButton,
              'click',
              () => {
                this._mouseOnDropdown = false;
                this.hide();
              }
            );
          }
        }
      );
    }
  }

  show() {
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      this._overlayRef.attach(this._dropdownInstance);

      setTimeout(() => {
        this._outsideClickListener = this._r2.listen(
          this._document,
          'click',
          () => this.hide()
        );
      });
    }
  }

  hide() {
    if (!this._mouseOnDropdown && this._overlayRef) {
      this._overlayRef.detach();
      this._outsideClickListener();
      this.onSelectorClosed.emit();
    }
  }
}
