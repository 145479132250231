import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { generateTimes } from '../../../utils/generate-times';

@Component({
  standalone: false,
  selector: 'norby-time-picker',
  templateUrl: './norby-time-picker.component.html',
  styleUrls: ['./norby-time-picker.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyTimePickerComponent),
      multi: true
    }
  ]
})
export class NorbyTimePickerComponent implements ControlValueAccessor, OnInit {
  @Input() label?: string;
  @Input() helperText?: string;
  @Input() errorText?: string;
  @Input() infoTooltip?: string;
  @Input() isDisabled?: boolean = false;
  @Input() isRequired?: boolean = false;
  @Input() showError?: boolean = false;
  @Input() keepParentOpen?: boolean = false;
  @Input() size: 'small' | 'medium' = 'medium';

  @Output() onHandleChange: EventEmitter<any> = new EventEmitter<any>();

  validTimes: string[];
  prompt: string;
  val: any;

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  constructor() {}

  ngOnInit(): void {
    const { validTimes } = generateTimes();
    this.validTimes = validTimes;
  }

  writeValue(value: any) {
    this.val = value;
    this.prompt = !this.val ? 'Choose time' : null;
  }

  handleValueChange(value: any) {
    this._onChanged(value);
    this._markAsTouched();
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }
}
