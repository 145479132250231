import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseComponent } from '../../../models';

@Component({
  standalone: false,
  selector: 'norby-text-area',
  templateUrl: './norby-text-area.component.html',
  styleUrls: ['./norby-text-area.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyTextAreaComponent),
      multi: true
    }
  ]
})
export class NorbyTextAreaComponent
  extends BaseComponent
  implements ControlValueAccessor, OnChanges, AfterViewInit
{
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() helperText?: string;
  @Input() infoTooltip?: string;
  @Input() maxLength?: number;
  @Input() value?: string;
  @Input() isDisabled?: boolean = false;
  @Input() isRequired?: boolean = false;
  @Input() readonly?: boolean = false;
  @Input() rows?: number;
  @Input() autoSize?: boolean = false;

  @ViewChild('textarea') textarea: ElementRef;

  val: string = '';

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.val = this.value;
    }

    if (
      this.textarea &&
      changes.autoSize &&
      changes.autoSize.previousValue !== changes.autoSize.currentValue
    ) {
      if (this.autoSize) {
        this._initAutoSize();
      } else {
        this._removeAutoSize();
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.autoSize) {
      this._initAutoSize();
    }
  }

  private _initAutoSize() {
    const el = this.textarea.nativeElement;
    el.setAttribute('style', `height: ${el.scrollHeight}px;overflow-y:hidden;`);
    el.addEventListener('input', OnTextAreaInput, false);
  }

  private _removeAutoSize() {
    const el = this.textarea.nativeElement;
    el.removeAttribute('style');
    el.removeEventListener('input', OnTextAreaInput, false);
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  writeValue(value: string) {
    this.val = value;
  }

  handleKeyup(target) {
    this.val = target.value;
    this._onChanged(target.value);
    this._markAsTouched();
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }
}

function OnTextAreaInput() {
  this.style.height = 'auto';
  this.style.height = this.scrollHeight + 'px';
}
