import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-steps-menu-item',
  templateUrl: './norby-steps-menu-item.component.html',
  styleUrls: ['./norby-steps-menu-item.component.less']
})
export class NorbyStepsMenuItemComponent {
  @Input() isSelected?: boolean = false;
  @Input() isDisabled?: boolean = false;
  @Input() isValid?: boolean = true;
  @Input() index: number;
  @Input() badgeContent?: string;
  @Input() label: string;
  @Input() iconName?: string;

  @Output() onMenuItemClicked: EventEmitter<number> =
    new EventEmitter<number>();

  onMenuItemClick(index) {
    if (!this.isDisabled) {
      this.onMenuItemClicked.emit(index);
    }
  }
}
