import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

import { BaseComponent } from 'uikit';
import {
  ContentClick,
  ContentRegisterable,
  IUserContent,
  SignupType
} from 'models';

import { ContentInteractionsService, UserService } from '../../services';

@Component({
  standalone: false,
  selector: 'lib-event-drop-card',
  templateUrl: './event-drop-card.component.html',
  styleUrls: ['./event-drop-card.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventDropCardComponent extends BaseComponent implements OnChanges {
  @Output() cardClick = new EventEmitter<ContentClick>();
  @Input() content: ContentRegisterable;
  @Input() overrideTitle: string;
  @Input() overrideSignupType: SignupType;

  userContent: IUserContent;
  hasLoaded = false;
  isFetching = false;

  constructor(
    private _user: UserService,
    private _interactions: ContentInteractionsService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges() {
    super.ngOnChanges();

    if (this.content?.contentId) {
      this._user
        .getUserContent$(this.content.contentId)
        .pipe(this.takeUntilChanges)
        .subscribe((e) => {
          this.userContent = e;
          this.hasLoaded = true;
          this._cdr.detectChanges();
        });
    }
  }

  handleClick(contentClick: ContentClick): void {
    this.cardClick.next(contentClick);
  }

  // Triggered when the user hovers over the send button
  // on newsletters
  // TODO: add for events and drops too?
  handleMenuStateChanged() {
    if (this.userContent || this.isFetching || !this.content?.contentId) return;

    this.isFetching = true;
    this._cdr.detectChanges();

    this._interactions
      .getUserContentForContent(this.content?.contentId)
      .then((userContent) => (this.userContent = userContent))
      .catch((e) => (this.userContent = null))
      .finally(() => {
        this.isFetching = false;
        this._cdr.detectChanges();
      });
  }
}
