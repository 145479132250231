import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, Optional } from '@angular/core';

// App
import {
  rootChevronDown,
  rootMessageCircle,
  rootXCircle,
  rootMail,
  rootTrash
} from '../../../icons';
import { THEME_CLASSES } from '../../constants';
import { IconService, MockContentInteractionsService } from '../../services';
import { IUserContent, ContentEvent, CUBE_EVENTS } from 'models';

/*
  Creates a main button (register/rsvp/purchase) for a given event
*/

@Component({
  standalone: false,
  selector: 'lib-event-main-button-view',
  templateUrl: './event-main-button-view.component.html',
  styleUrls: ['./event-main-button-view.component.less']
})
export class EventMainButtonViewComponent {
  @Input() event: ContentEvent;
  @Input() userEvent: IUserContent;
  @Input() isLoading = false;
  @Input() forceDisabled = false;

  // Analytics events
  userClickedPreEventCta = CUBE_EVENTS.userClickedPreEventCta;
  userClickedPostEventCta = CUBE_EVENTS.userClickedPostEventCta;

  constructor(
    private _http: HttpClient,
    private _interactions: MockContentInteractionsService,
    private _iconService: IconService,
    @Optional() @Inject(THEME_CLASSES) private _wrapperClasses
  ) {
    this._iconService.registerIcons([
      rootChevronDown,
      rootMessageCircle,
      rootXCircle,
      rootMail,
      rootTrash
    ]);
  }

  get isDisabled(): boolean {
    return (
      this.forceDisabled ||
      (this.event?.rsvpRestrictions &&
        this.event?.rsvpRestrictions?.remaining < 1) ||
      (this.event?.registrationCloseDate &&
        this.event?.isPastRegistrationDeadline)
    );
  }

  handleJoinClick() {
    const trackerUrl = this.userEvent?.shortLinks?.clickThroughShortLink;
    if (!trackerUrl) return;

    // Adding the `redirect=false` flag will stop the shortlink
    // service from actually doing the redirect (which would trigger
    // a CORS errror here) and instead just track the interaction
    // and return 200
    this._http.get(`${trackerUrl}/?redirect=false`).subscribe(
      (d) => console.log(`Joined: ${d}`),
      (e) => console.log(`Joined: ${e}`)
    );
  }

  handleRsvpClick() {
    this._interactions.registerForContent(this.event, this._wrapperClasses);
  }

  handleRemoveRSVP() {
    this._interactions.unregisterForContent(this.event, this._wrapperClasses);
  }

  handleEnableSMS() {
    this._interactions.toggleSmsForContent(
      this.event,
      true,
      this._wrapperClasses
    );
  }

  handleDisableSMS() {
    this._interactions.toggleSmsForContent(
      this.event,
      false,
      this._wrapperClasses
    );
  }

  handleEnableEmail() {
    this._interactions.toggleEmailForContent(
      this.event,
      true,
      this._wrapperClasses
    );
  }

  handleDisableEmail() {
    this._interactions.toggleEmailForContent(
      this.event,
      false,
      this._wrapperClasses
    );
  }

  get preLabel(): string {
    return this.event?.buttonLabels?.pre
      ? this.event.buttonLabels.pre
      : this.event?.tickets?.length > 0
        ? 'Tickets'
        : 'RSVP';
  }

  get preConfirmedLabel(): string {
    return this.event?.buttonLabels?.preConfirmed || "You're confirmed";
  }

  get midLabel(): string {
    return this.event?.buttonLabels?.mid || 'Join';
  }

  get postLabel(): string {
    return this.event?.buttonLabels?.post || 'Recap';
  }
}
