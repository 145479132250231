import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { plainToClass } from 'class-transformer';
import {
  ContentRegisterable,
  IPrompt,
  SegmentFilter,
  SegmentFilterTypes
} from 'models';

import dayjs from 'dayjs';

@Component({
  standalone: false,
  selector: 'norby-custom-content-filter',
  templateUrl: './norby-custom-content-filter.component.html',
  styleUrls: ['./norby-custom-content-filter.component.less']
})
export class NorbyCustomContentFilterComponent implements OnInit, OnChanges {
  @Input() content: ContentRegisterable;
  @Output() onAddFilter = new EventEmitter<SegmentFilter>();
  filterDisplay: string;
  selectedPrompt: IPrompt;
  formGroup: FormGroup;

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(): void {
    this.selectedPrompt = null;
    this.filterDisplay = null;
    this.content?.prompts?.forEach((prompt) => {
      if (!this.formGroup.contains(prompt.prompt)) {
        this.formGroup.addControl(prompt.prompt, new FormControl(''));
      } else {
        this.formGroup.patchValue({
          [prompt.prompt]: ''
        });
      }
    });

    this.formGroup?.patchValue(
      {
        type: null,
        date: null,
        operationType: null
      },
      { emitEvent: false }
    );
  }

  get isButtonDisabled() {
    let isDisabled = false;
    if (this.isDateFilter && !this.formGroup?.get('date')?.value) {
      isDisabled = true;
    }
    if (
      this.isPromptFilter &&
      !this.formGroup?.get(this.selectedPrompt?.prompt)?.value
    ) {
      isDisabled = true;
    }
    return isDisabled;
  }

  private _initForm(): void {
    const prompts =
      this.content?.prompts?.reduce((prompts, prompt) => {
        return {
          ...prompts,
          [prompt.prompt]: ['']
        };
      }, {}) ?? {};

    this.formGroup = this._formBuilder.group({
      ...prompts,
      type: [SegmentFilterTypes.REGISTERED, Validators.required],
      operationType: [],
      date: [null]
    });
  }

  handleOpenFilterDropdown() {}

  handleDateClick(operationType) {
    this.filterDisplay = `Registered ${operationType}`;
    this.selectedPrompt = null;

    this.formGroup.patchValue({
      type: SegmentFilterTypes.REGISTERED_DATE,
      operationType: operationType,
      options: null
    });
  }

  handleDatePickerPromptClick(operationType: string, prompt: IPrompt) {
    this.filterDisplay = `"${prompt.prompt}" is ${
      operationType !== 'on' ? operationType : ''
    }`;
    this.selectedPrompt = prompt;

    this.formGroup.patchValue({
      type: SegmentFilterTypes.RESPONDED_TO_DATE_PICKER_PROMPT,
      operationType: operationType,
      options: null
    });
  }

  handleDidCheckCheckbox(checkedBoxes: string[], promptName: string) {
    this.formGroup.get(`${promptName}`).setValue(checkedBoxes.join(', '));
  }

  handlePromptClick(prompt: IPrompt) {
    this.selectedPrompt = prompt;
    this.filterDisplay = prompt.prompt;
    this.formGroup.patchValue({
      type: SegmentFilterTypes.RESPONDED_TO_PROMPT,
      date: null
    });
  }

  get isDateFilter(): boolean {
    const type = this.formGroup?.get('type')?.value;
    return (
      type === SegmentFilterTypes.REGISTERED_DATE ||
      type === SegmentFilterTypes.RESPONDED_TO_DATE_PICKER_PROMPT
    );
  }

  get isPromptFilter(): boolean {
    const type = this.formGroup?.get('type')?.value;
    return type === SegmentFilterTypes.RESPONDED_TO_PROMPT;
  }

  handleAddFilter() {
    const response = this.formGroup.get(this.selectedPrompt?.prompt)?.value;
    const type = this.formGroup.get('type')?.value;
    const operationType = this.formGroup.get('operationType')?.value;
    const dateVal = this.formGroup.get('date')?.value;

    const filter = plainToClass(SegmentFilter, {
      type,
      contentId: this.content?.contentId,
      contentTitle: this.content?.title,
      ...(this.selectedPrompt?.prompt && {
        prompt: this.selectedPrompt?.prompt
      }),
      ...(response && { response }),
      ...(operationType && { operationType }),
      ...(dateVal && { date: dayjs(dateVal).toISOString() })
    });

    this.onAddFilter.emit(filter);
  }
}
