import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { NorbyTabComponent } from '../norby-tab';

@Component({
  standalone: false,
  selector: 'norby-tabs',
  templateUrl: './norby-tabs.component.html',
  styleUrls: ['./norby-tabs.component.less']
})
export class NorbyTabsComponent implements AfterContentInit {
  @Input() isContentFullWidth?: boolean = false;
  @Input() isHeaderFullWidth?: boolean = false;
  @Output() onTabChange = new EventEmitter<number>();
  @ContentChildren(NorbyTabComponent) tabs:
    | QueryList<NorbyTabComponent>
    | undefined;

  ngAfterContentInit() {
    let activeTab = this.tabs.first;

    for (const tab of this.tabs) {
      activeTab = tab.active ? tab : activeTab;
      tab.isContentFullWidth = this.isContentFullWidth;
    }

    this.handleTabClick(activeTab, false);
  }

  handleTabClick(clickedTab: NorbyTabComponent, emitEvent = true) {
    let activeIndex;
    this.tabs.forEach((tab, index) => {
      tab.active = tab === clickedTab;
      if (tab === clickedTab) {
        activeIndex = index;
      }
    });
    if (emitEvent) {
      this.onTabChange.emit(activeIndex);
    }
  }
}
