import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-alert',
  templateUrl: './norby-alert.component.html',
  styleUrls: ['./norby-alert.component.less']
})
export class NorbyAlertComponent {
  @Input() title: string;
  @Input() type: 'success' | 'info' | 'error' | 'alert' | 'simple' = 'simple';

  get iconName(): string {
    switch (this.type) {
      case 'success':
        return 'check';
      case 'alert':
        return 'alert_circle';
      case 'error':
        return 'x_circle';
      case 'info':
        return 'info';
      default:
        return '';
    }
  }
}
