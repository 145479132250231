import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

import {
  SOCIAL_TYPES,
  buildSocialLinksObjectFromBlock,
  SocialIconsSettings
} from 'models';
import {
  rootWebsite,
  rootTwitter,
  rootInstagram,
  rootLinkedin,
  rootFacebook,
  rootYoutube,
  rootTiktok,
  rootSoundcloud,
  rootClubhouse,
  rootTwitch,
  rootSpotify,
  rootApple,
  rootMedium,
  rootGithub,
  rootDribbble,
  rootPatreon,
  rootDiscord,
  rootPinterest,
  rootBandcamp,
  rootVenmo,
  rootCashapp,
  rootLastfm,
  rootPaypal,
  rootThreads,
  rootXSocial
} from '../../../icons';
import { IconService } from '../../services';

@Component({
  standalone: false,
  selector: 'lib-social-icons-view',
  templateUrl: './social-icons-view.component.html',
  styleUrls: ['./social-icons-view.component.less']
})
export class SocialIconsViewComponent implements OnChanges {
  @Input() iconOnly = true;
  @Input() filter: SOCIAL_TYPES[];
  @Input() socialLinks: SocialIconsSettings[];
  @Input() size: 'small' | 'default' | 'large' = 'large';
  @Input() preventDefault = false;
  @Input() center = true;
  @Input() type: 'text' | 'primary' | 'default' | 'dashed' = 'text';

  @Output() clicked = new EventEmitter<string>();

  links: Array<{ key: string; icon: string; label: string; url: string }>;

  constructor(private _iconService: IconService) {
    this._iconService.registerIcons([
      rootWebsite,
      rootTwitter,
      rootInstagram,
      rootLinkedin,
      rootFacebook,
      rootYoutube,
      rootTiktok,
      rootSoundcloud,
      rootClubhouse,
      rootTwitch,
      rootSpotify,
      rootApple,
      rootMedium,
      rootGithub,
      rootDribbble,
      rootPatreon,
      rootDiscord,
      rootPinterest,
      rootBandcamp,
      rootVenmo,
      rootCashapp,
      rootLastfm,
      rootPaypal,
      rootThreads,
      rootXSocial
    ]);
  }

  handleClick(event, url: string) {
    if (this.preventDefault) {
      event?.preventDefault();
    }

    this.clicked.emit(url);
  }

  ngOnChanges() {
    this.links = (
      this.socialLinks?.map?.((link) =>
        buildSocialLinksObjectFromBlock(link)
      ) || []
    ).filter((link) => link.url !== '');
  }
}
