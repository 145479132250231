import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-collapsible',
  templateUrl: './norby-collapsible.component.html',
  styleUrls: ['./norby-collapsible.component.less'],
  animations: [
    trigger('openClose', [
      state('open', style({})),
      state(
        'closed',
        style({
          height: '0px',
          padding: '0px',
          transform: 'scaleY(.9)',
          opacity: 0
        })
      ),
      transition('open => closed', [animate('0.12s ease-out')]),
      transition('closed => open', [animate('0.12s ease-in')])
    ]),
    trigger('openCloseCaret', [
      state(
        'open',
        style({
          transform: 'rotate(180deg) scale(.67)'
        })
      ),
      state('closed', style({})),
      transition('* => *', [animate('0.1s')])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NorbyCollapsibleComponent {
  @Input() title: string;
  @Input() opened: boolean = false;
  @Input() isDisabled: boolean = false;

  constructor(private _cdr: ChangeDetectorRef) {}

  handleToggleContent() {
    if (!this.isDisabled) {
      this.opened = !this.opened;
      this._cdr.detectChanges();
    }
  }
}
