import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';

import { plainToClass } from 'class-transformer';
import { filter, map, tap } from 'rxjs';

import {
  ButtonSingleSendBlock,
  DEFAULT_BLOCK_PADDING,
  DEFAULT_IMAGE_ALIGNMENT,
  ISlug,
  populateButtonOrCardTheme
} from 'models';
import { BaseComponent } from 'uikit';

@Component({
  standalone: false,
  selector: 'edit-email-button-block',
  templateUrl: './edit-email-button-block.component.html',
  styleUrls: ['./edit-email-button-block.component.less']
})
export class EditEmailButtonBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() block: ButtonSingleSendBlock;
  @Input() slug: ISlug;

  @Output() onUpdatedBlock: EventEmitter<ButtonSingleSendBlock> =
    new EventEmitter<ButtonSingleSendBlock>();

  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  get clickThroughUrlDefault(): string {
    return `https://${this.slug?.slug}.nor.by`;
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        height: this.block?.height,
        label: this.block?.label,
        clickThroughUrl: this.block?.clickThroughUrl,
        alignment: this.block?.alignment,
        ...(!!this.block?.theme?.button?.borderRadius && {
          borderRadius: Number(this.block?.theme?.button?.borderRadius)
        }),
        ...(!!this.block?.theme?.button?.borderWidth && {
          borderWidth: Number(this.block?.theme?.button?.borderWidth)
        }),
        dropShadow: this.block?.theme?.button?.dropShadow,
        backgroundColor: this.block?.theme?.button?.backgroundColor,
        textColor: this.block?.theme?.button?.textColor,
        borderColor: this.block?.theme?.button?.borderColor,
        paddingTop: this.block?.paddingTop,
        paddingRight: this.block?.paddingRight,
        paddingBottom: this.block?.paddingBottom,
        paddingLeft: this.block?.paddingLeft
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      height: [this.block.height ?? null],
      label: [this.block.label ?? ''],
      clickThroughUrl: [this.block.clickThroughUrl ?? ''],
      alignment: [this.block.alignment ?? DEFAULT_IMAGE_ALIGNMENT],
      borderRadius: [
        this.block?.theme?.button?.borderRadius,
        [Validators.min(0), Validators.max(100)]
      ],
      borderWidth: [
        this.block?.theme?.button?.borderWidth,
        [Validators.min(0), Validators.max(100)]
      ],
      dropShadow: [this.block.theme?.button?.dropShadow],
      backgroundColor: [this.block.theme?.button?.backgroundColor],
      textColor: [this.block.theme?.button?.textColor],
      borderColor: [this.block.theme?.button?.borderColor],
      paddingTop: [
        this.block?.paddingTop ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingRight: [
        this.block?.paddingRight ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingBottom: [
        this.block?.paddingBottom ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingLeft: [
        this.block?.paddingLeft ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) => {
          const buttonTheme = {
            button: populateButtonOrCardTheme(value)
          };
          return plainToClass(ButtonSingleSendBlock, {
            ...this.block,
            ...value,
            theme: buttonTheme
          });
        }),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }
}
