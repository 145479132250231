import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output
} from '@angular/core';

// App
import { MockShareService } from '../../services/share';

// Libs
import {
  IUserContent,
  ContentRegisterable,
  ContentEvent,
  ContentSignup,
  isEvent,
  CUBE_EVENTS
} from 'models';
import {
  rootCheckCircle,
  rootAlertCircle,
  rootHeart,
  rootGoogle,
  rootCalendar,
  rootApple,
  rootLink
} from '../../../icons';
import { THEME_CLASSES, MessageType } from '../../constants';
import { MessageService, IconService } from '../../services';

@Component({
  standalone: false,
  selector: 'lib-rsvp-confirmation-view',
  templateUrl: './rsvp-confirmation-view.component.html',
  styleUrls: ['./rsvp-confirmation-view.component.less']
})
export class RsvpConfirmationViewComponent implements OnInit, OnChanges {
  @Input() content: ContentRegisterable;
  @Input() userContent: IUserContent;
  @Input() isLoadingUserContent = false;
  @Input() themeClasses: string[];

  @Output() onSubmitAnother = new EventEmitter<void>();

  message: string;
  title: string;
  icon: string;
  canUseSystemShare = false;
  showCalendarOptions = false;

  // Analytics events
  saveToGcal = CUBE_EVENTS.userSavedToGcal;
  saveToIcal = CUBE_EVENTS.userSavedToIcal;
  support = CUBE_EVENTS.userTappedDonate;
  share = CUBE_EVENTS.userTappedShare;

  constructor(
    private _shareService: MockShareService,
    private _message: MessageService,
    private _iconService: IconService,
    @Optional() @Inject(THEME_CLASSES) private _wrapperClasses
  ) {
    this._iconService.registerIcons([
      rootCheckCircle,
      rootAlertCircle,
      rootHeart,
      rootCalendar,
      rootGoogle,
      rootApple,
      rootLink
    ]);
  }

  get showShare(): boolean {
    return (
      this.content?.hasEmailRequirement || this.content?.hasPhoneRequirement
    );
  }

  get showSubmitAnother(): boolean {
    return !this.showShare;
  }

  ngOnInit(): void {
    this.canUseSystemShare = this._shareService.canShare;
    this._initDisplay();
  }

  ngOnChanges(): void {
    this._initDisplay();
  }

  private _initDisplay() {
    this.title = "You're confirmed!";
    this.message = null;
    this.icon = 'check_circle';
    this.showCalendarOptions = false;

    if (this.content instanceof ContentEvent) {
      this.showCalendarOptions = true;

      if (this.content?.isHappening) {
        this.title = 'Happening now';
        this.icon = 'alert';
      } else if (this.content?.isOver) {
        this.title = 'Thanks';
        this.message = `This event has ended.`;
      } else {
        this.message = 'Add it to your calendar or share the link.';
      }
    } else if (this.content instanceof ContentSignup) {
      this.message = this.showSubmitAnother
        ? 'Your response has been recorded'
        : '';
    }
  }

  handleSubmitAnother() {
    this.onSubmitAnother.emit();
  }

  handleCopy() {
    this._message.show(
      { text: 'Copied', type: MessageType.SUCCESS },
      this.themeClasses ?? this._wrapperClasses
    );
  }

  doShare() {
    this._shareService.openContentShareSheet(this.content, this.userContent);
  }

  setGCal(): void {
    if (isEvent(this.content))
      this._shareService.saveToGoogleCalendar(this.content);
  }

  setICal(): void {
    if (isEvent(this.content)) {
      this._shareService.saveToICal(this.content, this.userContent.email);
    }
  }

  setOutlook(): void {
    if (isEvent(this.content)) {
      this._shareService.saveToICal(this.content, this.userContent.email);
    }
  }
}
