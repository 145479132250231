import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';

// Libs
import {
  CONTACT_LIST_COLUMN_NAME,
  ContactListColumn,
  IContact,
  PAGE_SIZE
} from 'models';
import { BaseComponent, IconService, rootCheckCircle } from 'uikit';

import { DeviceService } from '../../services';

@Component({
  standalone: false,
  selector: 'app-contacts-table-view',
  templateUrl: './contacts-table-view.component.html',
  styleUrls: ['./contacts-table-view.component.less']
})
export class ContactsTableViewComponent extends BaseComponent {
  @Input() totalItems = 0;
  @Input() currentPage = 1;
  @Input() pageSize = PAGE_SIZE;
  @Input() contacts: Array<IContact>;
  @Input() isLoading: boolean;
  @Input() columns: ContactListColumn[];
  @Input() footer: TemplateRef<any>;

  @Output() onPageChange = new EventEmitter<number>();
  @Output() onPageSizeChange = new EventEmitter<number>();
  @Output() onClickedContactAtIndex = new EventEmitter<number>();

  isMobile = false;

  constructor(
    private _iconService: IconService,
    private _device: DeviceService
  ) {
    super();
    this._iconService.registerIcons([rootCheckCircle]);
  }

  ngOnInit() {
    this._device.isMobile$
      .pipe(this.takeUntilDestroy)
      .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  get paginationType(): 'small' | 'default' {
    return this.isMobile ? 'small' : 'default';
  }

  trackBy(idx: number, item: IContact) {
    return item?.id;
  }

  handlePageChange(page: number) {
    this.onPageChange.emit(page);
  }

  handlePageSizeChange(size: number) {
    this.onPageSizeChange.emit(size);
  }

  handleShowContactDetails(index: number) {
    this.onClickedContactAtIndex.emit(index);
  }

  getNameForColumn(column: ContactListColumn): string {
    return CONTACT_LIST_COLUMN_NAME[column] || '';
  }
}
