import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  QueryList
} from '@angular/core';
import { NorbyCollapsibleComponent } from '../norby-collapsible/norby-collapsible.component';

@Component({
  standalone: false,
  selector: 'norby-collapsible-group',
  templateUrl: './norby-collapsible-group.component.html',
  styleUrls: ['./norby-collapsible-group.component.less']
})
export class NorbyCollapsibleGroupComponent implements AfterContentInit {
  // For now let's leave this here - with the new design this seems not to be needed anymore
  // @Input() isRounded?: boolean = true;
  // @Input() collapseStyle: 'bordered' | 'flush' = 'bordered';

  // @ContentChildren(NorbyCollapsibleComponent) panels:
  //   | QueryList<NorbyCollapsibleComponent>
  //   | undefined;

  constructor() {}

  ngAfterContentInit() {
    // For now let's leave this here - with the new design this seems not to be needed anymore
    // const panels = this.panels.toArray();
    // const lastIdx = panels.length - 1;
    // panels.forEach((panel, idx) => {
    //   panel.isFirst = idx === 0;
    //   panel.isLast = idx === lastIdx;
    //   panel.isGrouped = true;
    //   panel.isGroupRounded = this.isRounded;
    //   panel.collapseStyle = this.collapseStyle;
    // });
  }
}
