import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';

import { plainToClass } from 'class-transformer';
import { filter, map } from 'rxjs';

import {
  DividerSingleSendBlock,
  DEFAULT_BLOCK_PADDING,
  ISlug,
  isPaddingValid
} from 'models';
import { BaseComponent } from 'uikit';

@Component({
  standalone: false,
  selector: 'edit-email-divider-block',
  templateUrl: './edit-email-divider-block.component.html',
  styleUrls: ['./edit-email-divider-block.component.less']
})
export class EditEmailDividerBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() slug: ISlug;
  @Input() block: DividerSingleSendBlock;

  @Output() onUpdatedBlock: EventEmitter<DividerSingleSendBlock> =
    new EventEmitter<DividerSingleSendBlock>();
  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        height: this.block?.height,
        color: this.block?.theme?.card?.backgroundColor,
        paddingTop: this.block?.paddingTop,
        paddingRight: this.block?.paddingRight,
        paddingBottom: this.block?.paddingBottom,
        paddingLeft: this.block?.paddingLeft
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      height: [this.block.height ?? null],
      color: [this.block.theme?.card?.backgroundColor],
      paddingTop: [
        this.block?.paddingTop ?? DEFAULT_BLOCK_PADDING,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(300),
          isPaddingValid(
            { name: 'height', minValue: 32 },
            { name: 'paddingTop', minValue: 16 }
          )
        ]
      ],
      paddingRight: [
        this.block?.paddingRight ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingBottom: [
        this.block?.paddingBottom ?? DEFAULT_BLOCK_PADDING,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(300),
          isPaddingValid(
            { name: 'height', minValue: 32 },
            { name: 'paddingBottom', minValue: 16 }
          )
        ]
      ],
      paddingLeft: [
        this.block?.paddingLeft ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) =>
          plainToClass(DividerSingleSendBlock, {
            ...this.block,
            ...value,
            ...(value.color && {
              theme: { card: { backgroundColor: value.color } }
            })
          })
        ),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }
}
