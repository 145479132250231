import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconService, rootX } from 'uikit';

@Component({
  standalone: false,
  selector: 'app-filter-button-view',
  templateUrl: './filter-button-view.component.html',
  styleUrls: ['./filter-button-view.component.less']
})
export class FilterButtonViewComponent {
  @Input() title: string;
  @Input() isActive = false;
  @Input() isRemoveable = true;
  @Output() onClickButton = new EventEmitter<void>();

  constructor(private _iconService: IconService) {
    this._iconService.registerIcons([rootX]);
  }

  handleClick() {
    this.onClickButton.emit();
  }
}
