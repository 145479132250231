import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';

// App
import {
  IUserContent,
  ContentSignup,
  CUBE_EVENTS,
  IPromptResponse
} from 'models';
import { IconService, MockContentInteractionsService } from '../../services';
import { THEME_CLASSES } from '../../constants';
import {
  rootChevronDown,
  rootMessageCircle,
  rootXCircle,
  rootMail,
  rootTrash
} from '../../../icons';

/*
  Creates a main button (register/rsvp/purchase) for a given drop
*/

@Component({
  standalone: false,
  selector: 'lib-drop-main-button-view',
  templateUrl: './drop-main-button-view.component.html',
  styleUrls: ['./drop-main-button-view.component.less']
})
export class DropMainButtonViewComponent {
  @Input() drop: ContentSignup;
  @Input() userContent: IUserContent;
  @Input() isLoading = false;
  @Input() forceDisabled = false;
  @Input() promptResponses: IPromptResponse[];

  // Analytics events
  userClickedPreEventCta = CUBE_EVENTS.userClickedPreEventCta;
  userClickedPostEventCta = CUBE_EVENTS.userClickedPostEventCta;

  constructor(
    private _http: HttpClient,
    private _interactions: MockContentInteractionsService,
    private _iconService: IconService,
    @Inject(THEME_CLASSES) private _wrapperClasses
  ) {
    this._iconService.registerIcons([
      rootChevronDown,
      rootMessageCircle,
      rootXCircle,
      rootMail,
      rootTrash
    ]);
  }

  get isDisabled(): boolean {
    return (
      this.forceDisabled ||
      (this.drop?.rsvpRestrictions &&
        this.drop?.rsvpRestrictions?.remaining < 1) ||
      (this.drop?.registrationCloseDate &&
        this.drop?.isPastRegistrationDeadline)
    );
  }

  handleJoinClick() {
    const trackerUrl = this.userContent?.shortLinks?.clickThroughShortLink;
    if (!trackerUrl) return;

    // Adding the `redirect=false` flag will stop the shortlink
    // service from actually doing the redirect (which would trigger
    // a CORS errror here) and instead just track the interaction
    // and return 200
    this._http.get(`${trackerUrl}/?redirect=false`).subscribe(
      (d) => console.log(`Joined: ${d}`),
      (e) => console.log(`Joined: ${e}`)
    );
  }

  handleRsvpClick() {
    this._interactions.registerForContent(
      this.drop,
      this._wrapperClasses,
      this.promptResponses
    );
  }

  handleRemoveRSVP() {
    this._interactions.unregisterForContent(this.drop, this._wrapperClasses);
  }

  handleEnableSMS() {
    this._interactions.toggleSmsForContent(
      this.drop,
      true,
      this._wrapperClasses
    );
  }

  handleDisableSMS() {
    this._interactions.toggleSmsForContent(
      this.drop,
      false,
      this._wrapperClasses
    );
  }

  handleEnableEmail() {
    this._interactions.toggleEmailForContent(
      this.drop,
      true,
      this._wrapperClasses
    );
  }

  handleDisableEmail() {
    this._interactions.toggleEmailForContent(
      this.drop,
      false,
      this._wrapperClasses
    );
  }

  get preLabel(): string {
    return this.drop?.buttonLabels?.pre
      ? this.drop.buttonLabels.pre
      : this.drop?.tickets?.length > 0
        ? 'Tickets'
        : 'Sign up';
  }

  get preConfirmedLabel(): string {
    return this.drop?.buttonLabels?.preConfirmed || "You're confirmed";
  }
}
