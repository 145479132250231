import { Directive, HostListener, Input } from '@angular/core';
import { MockApiService } from '../services/';
import { ENDPOINTS, ApiSurfaces } from 'models';

/*
  Easy event tracking in template files. Send events to
  Segment, Cube, or both.

  Usage:

  .ts
  import { ANALYTICS_EVENTS } from 'src/app/constants';
  ...
  eventName = ANALYTICS_EVENTS.eventName;

  .html
  <a [logEvent]="eventName"
  [segmentData]="{ id: event.eventId }"
  [cubeData]="{ entityType: 'event', entityId: event.eventId }"
  [destination]="'all'">JOIN</a>
*/

@Directive({
  standalone: false,
  // tslint:disable-next-line: directive-selector
  selector: '[logEvent]'
})
export class LogEventDirective {
  constructor(private _api: MockApiService) {}
  @Input('logEvent') eventName: string;
  @Input() segmentData: any;
  @Input() cubeData: { entityType: string; entityId: string };

  @HostListener('click') onClick() {
    if (!this.cubeData?.entityId || !this.cubeData?.entityType) {
      return;
    }
    this._api
      .post(ApiSurfaces.END_USER, ENDPOINTS.analytics.trackEntityInteraction, {
        entityType: this.cubeData?.entityType,
        entityId: this.cubeData?.entityId,
        interactionType: this.eventName,
        domReferrer: document?.referrer
      })
      .catch((e) => console.log(e));
  }
}
