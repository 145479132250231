import { Component, TemplateRef, ViewChild } from '@angular/core';
import { IconService, rootLoader } from 'uikit';

@Component({
  standalone: false,
  selector: 'lib-loading-indicator-view',
  templateUrl: './loading-indicator-view.component.html',
  styleUrls: ['./loading-indicator-view.component.less']
})
export class LoadingIndicatorViewComponent {
  @ViewChild('nzIndicatorTpl', { static: true })
  nzIndicator!: TemplateRef<void>;

  constructor(private _iconService: IconService) {
    this._iconService.registerIcons([rootLoader]);
  }
}
