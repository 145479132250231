import {
  Component,
  PLATFORM_ID,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
  Output
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// 3rd party
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import { filter, map } from 'rxjs/operators';

// App
import { BaseComponent } from 'uikit';
import { ContentClick, ContentEvent, UpcomingEventsPageBlock } from 'models';
import { ContentService } from '../../services';

// Helper method to filter out immaterial event list
// updates
const eventsListsAreEqual = (
  one: ContentEvent[],
  two: ContentEvent[]
): boolean => {
  if (one?.length !== two?.length) return false;
  let listsAreEqual = true;
  for (let i = 0; i < one.length; i++) {
    if (
      one[i]?.contentId !== two[i]?.contentId ||
      one[i]?.title !== two[i]?.title ||
      one[i]?.subtitle !== two[i]?.subtitle ||
      one[i]?.imageUrl !== two[i]?.imageUrl
    ) {
      listsAreEqual = false;
      break;
    }
  }
  return listsAreEqual;
};

type EventBucketMap = { [label: string]: ContentEvent[] };

/*
  Helper method to collapse together events starting at the same
  time
*/
const eventBucketsFromEvents = (events: ContentEvent[]): EventBucketMap => {
  const weekStart = dayjs().startOf('week');
  return events.reduce((prev, event) => {
    const eventDate = event.startMoment();
    const eventWeekStart = eventDate.startOf('week');
    const weekDiff = eventWeekStart.diff(weekStart, 'week');
    const label =
      event.isToday && event.isOver
        ? 'Earlier'
        : event.isToday
          ? 'Today'
          : weekDiff === 0
            ? 'This week'
            : weekDiff === 1
              ? 'Next week'
              : weekDiff === 2
                ? 'In 2 weeks'
                : weekDiff === 3
                  ? 'In 3 weeks'
                  : eventWeekStart.fromNow();

    const bucket = prev[label] || (prev[label] = new Array<ContentEvent>());
    bucket.push(event);
    return prev;
  }, {});
};

@Component({
  standalone: false,
  selector: 'lib-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcomingEventsComponent
  extends BaseComponent
  implements OnChanges
{
  @Output() cardClick = new EventEmitter<ContentClick>();
  @Input() block: UpcomingEventsPageBlock;
  events: ContentEvent[] = [null];
  eventBuckets: EventBucketMap = { '': [null, null] };

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _cdr: ChangeDetectorRef,
    private _content: ContentService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (!isPlatformBrowser(this._platform)) return;

    this._content
      .getEventsForCurrentSlug$({
        ...(this.block?.limit >= 0 && { limit: this.block.limit }),
        ...(this.block?.reverse && { sort: 'desc' }),
        ...(this.block?.tag && { tag: this.block.tag })
      })
      // We don't want items to rebuild on an emission (which can happen at any time)
      // unless we actually have new events to display
      .pipe(
        filter((summary) => !!summary),
        map((summary) => summary.items),
        filter((events) => !eventsListsAreEqual(events, this.events)),
        this.takeUntilChanges
      )
      .subscribe((events) => {
        this.events = events;
        this.eventBuckets = eventBucketsFromEvents(this.events);
        this._cdr.detectChanges();
      });
  }

  // Comparator for keyvalue pipe to force Angular to preserve
  // the order of the event bucket map
  preserveOrder = (a, b): number => 0;

  eventTrackBy(idx: number, event: ContentEvent) {
    return event?.contentId;
  }

  bucketTrackBy(idx: number, bucket: any) {
    return bucket?.key ?? idx;
  }

  handleCardClick(contentClick: ContentClick) {
    if (!contentClick?.content) return;
    this.cardClick.next(contentClick);
  }
}
