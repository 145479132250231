import { Component, Input } from '@angular/core';
import { SpacerPageBlock } from 'models';

@Component({
  standalone: false,
  selector: 'lib-spacer-view',
  templateUrl: './spacer-view.component.html',
  styleUrls: ['./spacer-view.component.less']
})
export class SpacerViewComponent {
  @Input() block: SpacerPageBlock;

  get height(): string {
    return `${this.block?.height || 50}px`;
  }
}
