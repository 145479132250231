import { Directive } from '@angular/core';

@Directive({
  standalone: false,
  selector: '[result-title]',
  host: {
    class: 'text-xl text-neutral-900 text-center'
  }
})
export class ResultTitleDirective {}

@Directive({
  standalone: false,
  selector: '[result-subtitle]',
  host: {
    class: 'text-modal-backdrop text-base text-center'
  }
})
export class ResultSubtitleDirective {}

@Directive({ standalone: false, selector: '[result-icon]' })
export class ResultIconDirective {}

@Directive({
  standalone: false,
  selector: '[result-content]',
  host: {
    class: 'mt-6 py-6 px-10 bg-neutral-200'
  }
})
export class ResultContentDirective {}

@Directive({
  standalone: false,
  selector: '[result-extra]',
  host: {
    class: 'mt-6 text-center'
  }
})
export class ResultExtraDirective {}
