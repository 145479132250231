import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  LandingPage,
  ISlug,
  ProductAnalyticsEventTypes,
  ClickedElementProductAnalyticsElementCTAEnum,
  ClickedElementProductAnalyticsElementTypeEnum
} from 'models';
import {
  IconService,
  rootCopy,
  rootMessageCircle,
  rootLink,
  MessageService,
  MessageType
} from 'uikit';
import { slideIn } from 'customer-uikit';

@Component({
  standalone: false,
  selector: 'lib-my-norby-view',
  templateUrl: './my-norby-view.component.html',
  styleUrls: ['./my-norby-view.component.less'],
  animations: [slideIn]
})
export class MyNorbyViewComponent {
  @Input() page: LandingPage;
  @Input() slug: ISlug;
  @Input() rootUrl: string;
  @Input() phoneNumber: string;
  @Input() phoneNumberTooltip: string;
  @Input() allowPhoneNumberCopy = false;

  @Output() onEditClick = new EventEmitter<void>();
  @Output() onViewClick = new EventEmitter<void>();

  // click element analytics
  readonly TRACK_CLICK_EVENT = ProductAnalyticsEventTypes.CLICKED_ELEMENT;
  readonly TRACK_COPY_NUMBER_PROPERTIES = {
    elementType: ClickedElementProductAnalyticsElementTypeEnum.BUTTON,
    elementCTA: ClickedElementProductAnalyticsElementCTAEnum.COPY_NORBY_NUMBER
  };
  readonly TRACK_COPY_LINK_PROPERTIES = {
    elementType: ClickedElementProductAnalyticsElementTypeEnum.BUTTON,
    elementCTA: ClickedElementProductAnalyticsElementCTAEnum.COPY_NORBY_LINK
  };

  constructor(
    private _message: MessageService,
    private _iconService: IconService
  ) {
    this._iconService.registerIcons([rootCopy, rootMessageCircle, rootLink]);
  }

  handleCopy() {
    this._message.show({
      text: 'Copied',
      type: MessageType.SUCCESS
    });
  }

  handleEditClick() {
    this.onEditClick.emit();
  }

  handleViewClick() {
    this.onViewClick.emit();
  }
}
