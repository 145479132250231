import { Component, Input } from '@angular/core';

/**
 * @param isContentFullWidth - if true, the content of the tab will be full width (no horizontal padding)
 */
@Component({
  standalone: false,
  selector: 'norby-tab',
  templateUrl: './norby-tab.component.html',
  styleUrls: ['./norby-tab.component.less']
})
export class NorbyTabComponent {
  @Input() title: string;
  @Input() active? = false;
  @Input() isContentFullWidth? = false;

  constructor() {}
}
