import {
  Component,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';

import { BaseComponent } from 'uikit';
import { IUserContent, ContentEvent } from 'models';
import { UserService } from '../../services';

/*
  Creates a share button for a given event/drop and manages a subscription
  to the corresponding UserContent
*/

@Component({
  standalone: false,
  selector: 'lib-event-drop-share-button',
  templateUrl: './event-drop-share-button.component.html',
  styleUrls: ['./event-drop-share-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventDropShareButtonComponent
  extends BaseComponent
  implements OnChanges
{
  @Output() menuStateChanged = new EventEmitter<boolean>();
  @Input() content: ContentEvent;
  @Input() size: 'default' | 'large' | 'small' = 'default';
  @Input() type: 'primary' | 'dashed' | 'link' | 'text' = 'text';
  @Input() showText = false;
  userContent: IUserContent;
  hasLoaded = false;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _user: UserService
  ) {
    super();
  }

  ngOnChanges(): void {
    super.ngOnChanges();

    if (this.content?.contentId)
      this._user
        .getUserContent$(this.content.contentId)
        .pipe(this.takeUntilChanges)
        .subscribe((e) => {
          this.hasLoaded = true;
          this.userContent = e;
          this._cdr.detectChanges();
        });
  }

  handleVisibleChanged(event) {
    this.menuStateChanged.emit(event);
  }
}
