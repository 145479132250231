import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { ENDPOINTS, ApiSurfaces, SpotifyPageBlock, uuidv4 } from 'models';

import { MockApiService } from '../../services';
import {
  DO_NOT_TRACK_ANALYTICS,
  SPOTIFY_IFRAME_API_SELECTOR,
  SPOTIFY_IFRAME_ID_PREFIX
} from '../../constants';
import { SpotifyEmbedController, SpotifyEmbedOptions } from './types';

@Component({
  standalone: false,
  selector: 'lib-spotify-embed-view',
  templateUrl: './spotify-embed-view.component.html',
  host: { ngSkipHydration: 'true' }
})
export class SpotifyEmbedViewComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() block: SpotifyPageBlock;
  @Input() pageId: string;

  @ViewChild('iframePlaceholder') iframePlaceholder: ElementRef;

  iframeId: string;
  private _embedController: SpotifyEmbedController;
  private _isPaused: boolean;

  constructor(
    @Inject(PLATFORM_ID) private _platformId,
    @Inject(DO_NOT_TRACK_ANALYTICS)
    @Optional()
    private _doNotTrackAnalytics: boolean,
    @Inject(DOCUMENT) private _document: any,
    private _renderer: Renderer2,
    private _api: MockApiService
  ) {
    this.iframeId = `${SPOTIFY_IFRAME_ID_PREFIX}${uuidv4()}`;
  }

  async ngAfterViewInit(): Promise<void> {
    await this._loadSpotifyPlayer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { previousValue, currentValue } = changes.block || {};
    if (previousValue && currentValue) {
      const previousOptions = this._buildIframeOptions(previousValue);
      const currentOptions = this._buildIframeOptions(currentValue);
      if (!this._embedOptionsAreEqual(previousOptions, currentOptions)) {
        this._refreshEmbed(currentOptions);
      }
    }
  }

  ngOnDestroy(): void {
    this._embedController?.destroy();
  }

  private _embedOptionsAreEqual(
    a: SpotifyEmbedOptions,
    b: SpotifyEmbedOptions
  ) {
    for (const [key, val] of Object.entries(a)) {
      if (b[key] !== val) return false;
    }
    return true;
  }

  private _refreshEmbed(options: SpotifyEmbedOptions) {
    this._embedController.options = options;

    try {
      this._embedController.loadUri(options.uri);
    } catch (e) {}
  }

  private _convertSpotifyUrlForIframe(url: string): string | null {
    let spotifyUrlParsed: URL;
    try {
      spotifyUrlParsed = new URL(url);
    } catch (e) {
      return null;
    }

    // the pathname is in the format /${artist | episode | track | playlist | etc}/${id}
    return `spotify${spotifyUrlParsed.pathname.replace(/\//g, ':')}`;
  }

  private _buildIframeOptions(block: SpotifyPageBlock): SpotifyEmbedOptions {
    const spotifyUri = this._convertSpotifyUrlForIframe(block.url);
    return {
      width: `${block.width ?? '100%'}`,
      height: `${block.height ?? 352}`,
      uri: spotifyUri,
      ...(block.hideColor && { theme: 'dark' })
    };
  }

  private async _loadSpotifyPlayer() {
    const spotifyApi = await this._getSpotifyApi();

    try {
      spotifyApi.createController(
        this._document.querySelector(`#${this.iframeId}`),
        this._buildIframeOptions(this.block),
        (ec) => {
          ec.addListener('playback_update', (e) => {
            this._handlePlaybackUpdate(e);
          });
          this._embedController = ec;
        }
      );
    } catch (e) {}
  }

  private _handlePlaybackUpdate(e: any) {
    const lastIsPaused = this._isPaused;
    this._isPaused = e.data.isPaused;

    if (lastIsPaused === this._isPaused) return;

    if (!this._isPaused) this._recordSpotifyPlay();
  }

  private _recordSpotifyPlay() {
    if (this._doNotTrackAnalytics) return;

    const spotifyUri = this._convertSpotifyUrlForIframe(this.block.url);
    const [, embedType, embedId] = spotifyUri;
    const shortUrl = `https://open.spotify.com/${embedType}/${embedId}`;
    this._api
      .post(ApiSurfaces.END_USER, ENDPOINTS.analytics.trackEntityInteraction, {
        entityType: 'collection',
        entityId: this.pageId,
        interactionType: 'spotifyPlay',
        interactionValue: spotifyUri,
        interactionMetadata: { url: shortUrl },
        domReferrer: this._document.referrer
      })
      .catch();
  }

  private async _getSpotifyApi() {
    if (!isPlatformBrowser(this._platformId)) {
      return;
    }

    if (window[SPOTIFY_IFRAME_API_SELECTOR]) {
      return window[SPOTIFY_IFRAME_API_SELECTOR];
    }

    const scriptId = `${SPOTIFY_IFRAME_API_SELECTOR}-script`;
    const spotifyScript = this._document.querySelector(`#${scriptId}`);
    if (!spotifyScript) {
      (window as any).onSpotifyIframeApiReady = (IFrameAPI) => {
        window[SPOTIFY_IFRAME_API_SELECTOR] = IFrameAPI;
      };

      const newScript = this._renderer.createElement('script');
      this._renderer.setAttribute(newScript, 'id', scriptId);
      this._renderer.setAttribute(
        newScript,
        'src',
        'https://open.spotify.com/embed/iframe-api/v1'
      );

      const firstScript = this._document.getElementsByTagName('script')[0];
      this._renderer.insertBefore(
        this._renderer.parentNode(firstScript),
        newScript,
        firstScript
      );
    }

    await new Promise((resolve) => setTimeout(resolve, 10));
    return this._getSpotifyApi();
  }
}
