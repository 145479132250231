import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// 3rd party
import { fromEvent, merge, Observable, Subscriber } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

// App
import { MockDeviceService, FixedHeightComponent } from 'uikit';
import { fadeInOut } from '../../animations';

// Debounce time for resetting position on window resize events
// or content size changes
const RESIZE_DEBOUNCE_TIME = 80;

// Height of drawer when in loading position
const LOADING_HEIGHT = 150;

// Height of drawer in collapsed state
const COLLAPSED_HEIGHT = 80;

// Maximum height drawer can reach before needing to be
// expanded for all of its content to be seen
const MAX_HEIGHT_BEFORE_EXPANDING_FRACTION = 0.8;

// Interval for calculating momentum at the end of a drag
const MOMENTUM_LOOKBACK_MS = 150;

// Describes overall layout of the drawer at a given moment
type Layout = {
  resetPoint: number;
  canScroll: boolean;
  canExpand: boolean;
};

// Describes a particular mouse or touch move event during a drag
type Move = {
  event: MouseEvent | TouchEvent;
  timestamp: number;
};

// A given drag sequence
class Drag {
  events: Array<Move> = [];

  constructor(public initialDrawerOffset: number) {}

  // The most recent move event
  get lastMove(): Move {
    return this.events.length ? this.events[this.events.length - 1] : null;
  }

  // The global Y coordinate of the first move event
  get initialMoveOffset(): number {
    return this.events?.[0]?.event?.['pageY'] ?? 0;
  }

  // The global Y coordinate of the most recent move event
  get lastMoveOffset(): number {
    return this.lastMove?.event?.['pageY'] ?? 0;
  }

  // The calculated current offset for the drawer in global coordinate space
  get currentDrawerOffset(): number {
    return this.initialDrawerOffset - this.dragDelta;
  }

  // How much vertical distance we've traversed with this drag
  get dragDelta(): number {
    return this.initialMoveOffset - this.lastMoveOffset;
  }

  // Add a move event to the sequence and timestamp it
  addMove(event: MouseEvent | TouchEvent): number {
    return this.events.push({ event, timestamp: Date.now() });
  }

  // Find the last move within the momentum lookback window
  // used to calculate velocity
  lastInterestingMove(): Move {
    if (!this.events.length) {
      return null;
    }

    let i = this.events.length;
    const endTime = Date.now();

    while (i--) {
      if (endTime - this.events[i].timestamp > MOMENTUM_LOOKBACK_MS) {
        break;
      }
    }

    const moveIdx = Math.min(i + 1, this.events.length - 1);
    const move = this.events[moveIdx];
    const timeDelta = endTime - move.timestamp;
    return timeDelta < MOMENTUM_LOOKBACK_MS ? move : null;
  }

  // Calculate the velocity of the user's drag to better
  // calculate a point to snap to when they release
  // Iterate through the most recent drag moves in the stream
  // to calculate how fast the user was dragging/flicking
  // and in what direction
  velocity(): number {
    const lastMove = this.lastMove;
    const lastInterestingMove = this.lastInterestingMove() ?? lastMove;
    const lmY = lastMove?.event?.['pageY'] || 1;
    const lmTime = lastMove?.timestamp || 1;
    const lmiY = lastInterestingMove?.event?.['pageY'] || 1;
    const lmiTime = lastInterestingMove?.timestamp || 1;
    const yDelta = lmY - lmiY;
    const timeDelta = lmTime - lmiTime;
    return timeDelta ? yDelta / timeDelta : 0;
  }
}

@Component({
  standalone: false,
  selector: 'lib-expandable-bottom-sheet-view',
  templateUrl: './expandable-bottom-sheet-view.component.html',
  styleUrls: ['./expandable-bottom-sheet-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut]
})
export class ExpandableBottomSheetViewComponent
  extends FixedHeightComponent
  implements AfterViewInit, OnChanges
{
  @Input() loading = false; // Show a loading indicator instead of the drawer's contents
  @Input() collapsible = false; // Whether the drawer should be allowed to collapse or not
  @Input() flush = false; // Whether to hide horizontal padding
  @Input() showDropShadow = false; // Whether to show a drop shadow behind the sheet
  @Input() collapsedText: string; // If specified will replace drawer contents on collapse
  @Input() backgroundColor: string; // If specified will override the neutral background color
  @Input() overlayMask: boolean = false; // Add a dark overlay to the area behind the bottom sheet
  @Input() enableResizeListener: boolean = true; // Parent can override resize behavior if desired
  @Input() expandedPosition = 0; // Vertical offset of sheet when in expanded position

  // Emits event when area behind bottom sheet is clicked
  // Usually used when the overlayMask property is applied
  @Output() backgroundClicked = new EventEmitter<void>();

  // Emits event whenever layout updates, e.g. when the user
  // drags the sheet up and down and the amount of visible
  // space behind it changes
  @Output() availableSpaceChanged = new EventEmitter<number>();

  @ViewChild('bottomSheetInner') bottomSheetInner: ElementRef; // Drawer inner contents
  @ViewChild('bottomSheetContainer') bottomSheetContainer: ElementRef; // Drawer wrapper
  @ViewChild('viewportWrapper') viewportWrapper: ElementRef; // Scroll container

  private _currentOffset = 2000;
  private _currentDrag: Drag;
  private _currentLayout: Layout;

  private _dragStart$: Observable<MouseEvent | TouchEvent>;
  private _dragEnd$: Observable<MouseEvent | TouchEvent>;

  // Whether the user is currently dragging
  get isDragging(): boolean {
    return !!this._currentDrag;
  }

  // Current transform applied to the bottom sheet
  get bottomSheetTransform(): string {
    return `translateY(${this._currentOffset}px)`;
  }

  // Current padding applied to the bottom sheet container
  // Since bottom sheet container always has a height of 100vh,
  // we have to add padding to the bottom of the container to
  // account for the offset possibly specified by expandedPosition
  get bottomSheetPadding(): string {
    return `0px 0px ${this.expandedPosition}px`;
  }

  // Whether the bottom sheet is expanded to the top
  // of the screen
  get isExpanded(): boolean {
    return this._currentOffset <= this.expandedPosition;
  }

  // Whether the bottom sheet is currently collapsed
  get isCollapsed(): boolean {
    return this._currentOffset >= this.viewportHeight - COLLAPSED_HEIGHT;
  }

  // Whether the bottom sheet is scrollable
  // Bottom sheet can only be scrolled if it's in the expanded
  // position and the inner contents still overflow
  get isScrollable(): boolean {
    return this.isExpanded && this._currentLayout.canScroll;
  }

  // Height setting for the inner content area of the bottom sheet
  // Fixed height if bottom sheet is currently in loading state
  get bottomSheetInnerHeight(): string {
    return this.loading ? `${LOADING_HEIGHT}px` : 'auto';
  }

  // Whether the bottom sheet is currently visible on screen or is
  // being hidden by parent CSS
  get isBeingDisplayed(): boolean {
    return (
      this.viewportWrapper?.nativeElement &&
      this.viewportWrapper.nativeElement.offsetHeight !== 0 &&
      this.viewportWrapper.nativeElement.offsetWidth !== 0
    );
  }

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _device: MockDeviceService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(): void {
    super.ngOnChanges();

    if (isPlatformBrowser(this._platform)) {
      this.layoutAndAnimateSheet();
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platform)) {
      this.layoutAndAnimateSheet();
      this._initDragListeners();
      this._initResizeObserver();
    }
  }

  private _initDragListeners() {
    // Build drag start stream
    const sheet = this.bottomSheetContainer.nativeElement;
    const mouseDown$ = fromEvent<MouseEvent>(sheet, 'mousedown', {
      passive: true
    });
    const touchDown$ = fromEvent<TouchEvent>(sheet, 'touchstart', {
      passive: true
    });
    this._dragStart$ = merge(mouseDown$, touchDown$).pipe(
      this.takeUntilDestroy
    );

    // Build drag end stream
    const mouseUp$ = fromEvent<MouseEvent>(this._document, 'mouseup', {
      passive: true
    });
    const touchUp$ = fromEvent<TouchEvent>(this._document, 'touchend', {
      passive: true
    });
    const touchCancel$ = fromEvent<TouchEvent>(this._document, 'touchcancel');
    this._dragEnd$ = merge(mouseUp$, touchUp$, touchCancel$).pipe(
      this.takeUntilDestroy
    );

    // Start listening for drag start and drag end events
    // We only start processing drag move events once a drag
    // has actually started
    this._dragStart$.subscribe(this.dragStart);
    this._dragEnd$.subscribe(this.dragEnd);
  }

  // Watch for inner content or window resizing and rebuild layout as needed
  private _initResizeObserver() {
    const innerEl = this.bottomSheetInner.nativeElement;
    const innerResize$ = new Observable(
      (subscriber: Subscriber<ResizeObserverEntry[]>) => {
        const resizeObserver = new ResizeObserver(
          (entries: ResizeObserverEntry[]) => subscriber.next(entries)
        );
        resizeObserver.observe(innerEl);
        return () => resizeObserver.disconnect();
      }
    );

    merge(innerResize$, this._device.resize$)
      .pipe(
        debounceTime(RESIZE_DEBOUNCE_TIME),
        // Don't reset unless all three conditions are true:
        // 1. The drawer is visible on screen
        // 2. The inner contents changed OR
        //      the window resize listener fired and the enabledResizeListener flag is set
        // 3. The layout was updated
        filter(
          (event) =>
            this.isBeingDisplayed && // 1
            (this.enableResizeListener || !(event instanceof Event)) && // 2
            this._rebuildLayout() // 3
        ),
        this.takeUntilDestroy
      )
      .subscribe(() => this.resetSheet());
  }

  // Animate to a new drawer position
  private _updateOffset(newOffset: number) {
    if (!this.isBeingDisplayed) {
      return;
    }

    const adjustedOffset = Math.min(
      Math.max(newOffset, this.expandedPosition),
      this.viewportHeight
    );
    this._currentOffset = adjustedOffset;
    this._cdr.detectChanges();
    this.availableSpaceChanged.next(adjustedOffset);
  }

  // Handler for drag start events
  dragStart = (event: MouseEvent | TouchEvent) => {
    // Create a new Drag initialized to the current position
    this._currentDrag = new Drag(this._currentOffset);

    // Start listening for drag move events
    const mouseMove$ = fromEvent<MouseEvent>(this._document, 'mousemove', {
      passive: true
    });
    const touchMove$ = fromEvent<TouchEvent>(this._document, 'touchmove', {
      passive: true
    });

    // Stream drag moves and add them to the current Drag
    merge(mouseMove$, touchMove$)
      .pipe(
        tap((e) => this._currentDrag.addMove(e)),
        takeUntil(this._dragEnd$)
      )
      .subscribe(this.dragMove);
  };

  // Handler for drag move events
  // Update drawer position if needed
  dragMove = (event: MouseEvent | TouchEvent) => {
    if (!this.isDragging) {
      return;
    }

    const currentOffset = this._currentDrag.currentDrawerOffset;
    const scrollTop = this.bottomSheetContainer.nativeElement.scrollTop;

    // Update the current transform if:
    // - The container is not scrollable and can be freely dragged
    // - The container is scrollable, anchored to the top, not currently
    //   scrolled, and the user is pulling down
    if (!this.isScrollable || (currentOffset > 0 && scrollTop === 0)) {
      this._updateOffset(currentOffset);
    }
  };

  // Handler for drag end events
  // Calculate the best position to snap to and animate
  // to that position
  dragEnd = (event: MouseEvent | TouchEvent) => {
    if (!this.isDragging) {
      return;
    }

    // End the current drag sequence and animate the drawer
    // to the best available snap target
    const snapTarget = this._bestAvailableSnapPoint();
    this._currentDrag = null;
    this._updateOffset(snapTarget);
  };

  // Return the best available snap point
  // Drawers have three possible snap points:
  // - Expanded (drawer covers the full screen)
  // - Reset (natural resting position of the drawer)
  // - Collapsed (drawer is snapped to the bottom of the screen)
  private _bestAvailableSnapPoint(): number {
    const momentum = this._currentDrag?.velocity() ?? 1;
    const collapsedY = this.viewportHeight - COLLAPSED_HEIGHT;

    // Calculate the distance to each potential snap point
    const dExpanded = this.expandedPosition - this._currentOffset;
    const dCollapsed = collapsedY - this._currentOffset;
    const dReset = this._currentLayout.resetPoint - this._currentOffset;

    // First create an array of allowed target distances
    // Then sort them by their magnitude
    // - Expanding is allowed if the current layout says so
    //   and the direction of the momentum is correct
    // - Collapsing is allowed if the parent says so and the
    //   direction of the momentum is correct
    // - Resetting is always allowed
    const canExpand =
      this._currentLayout.canExpand && dExpanded * momentum >= 0;
    const canCollapse = this.collapsible && dCollapsed * momentum >= 0;
    const distances = [
      ...(canExpand ? [dExpanded] : []),
      ...(canCollapse ? [dCollapsed] : []),
      dReset
    ].sort((a, b) => Math.abs(a) - Math.abs(b));

    // The smallest distance is the one we'll pick to snap to
    const targetDistance = distances[0];
    const targetOffset = this._currentOffset + targetDistance;
    return targetOffset;
  }

  // Viewport height is based off the height of the wrapper
  // element if available instead of window properties
  // Window properties on iOS mobile Safari include space
  // that is obscured by the toolbar at the bottom
  // The viewport wrapper uses `100vh` as its height but with
  // `max-height: -webkit-fill-available` set as well, which
  // forces it to only fill the visible space above the toolbar
  // This makes it a better reference to base our calculations on
  get viewportHeight() {
    if (!isPlatformBrowser(this._platform)) {
      return 0;
    }

    return (
      this.viewportWrapper?.nativeElement?.clientHeight ??
      window?.screen?.availHeight ??
      window?.innerHeight
    );
  }

  // Rebuild the current Layout object without triggering
  // change detection
  private _rebuildLayout(): boolean {
    let resetPoint = this.viewportHeight - LOADING_HEIGHT;
    let canScroll = false;
    let canExpand = false;

    if (!this.loading) {
      const bottomSheetEl = this.bottomSheetInner?.nativeElement;
      const bottomSheetElTotalHeight = bottomSheetEl?.scrollHeight;
      const maxHeight =
        MAX_HEIGHT_BEFORE_EXPANDING_FRACTION * this.viewportHeight;
      const mainElementHeight = bottomSheetElTotalHeight
        ? Math.min(bottomSheetElTotalHeight, maxHeight)
        : maxHeight;
      resetPoint = this.viewportHeight - mainElementHeight;
      canExpand = bottomSheetElTotalHeight > mainElementHeight;
      canScroll =
        bottomSheetElTotalHeight > this.viewportHeight - this.expandedPosition;
    }

    const oldLayout = this._currentLayout;
    this._currentLayout = { resetPoint, canScroll, canExpand };

    return (
      !oldLayout ||
      oldLayout.resetPoint !== resetPoint ||
      oldLayout.canScroll !== canScroll ||
      oldLayout.canExpand !== canExpand
    );
  }

  // Reset layout and animate sheet to its default position
  layoutAndAnimateSheet(to: 'default' | 'collapsed' | 'expanded' = 'default') {
    if (!this.isBeingDisplayed) {
      return;
    }

    this._currentDrag = null;
    this._rebuildLayout();

    // Reset scroll of inner container regardless of which position we're snapping to
    if (this.bottomSheetContainer?.nativeElement?.scrollTop) {
      this.bottomSheetContainer.nativeElement.scrollTop = 0;
    }

    // Animate to the snap point
    if (to === 'collapsed' && this.collapsible) {
      this.collapseSheet();
    } else if (to === 'expanded' && this._currentLayout.canExpand) {
      this.expandSheet();
    } else {
      this.resetSheet();
    }
  }

  // Programmatically expand sheet to fill the viewport
  expandSheet(): void {
    this._updateOffset(0);
  }

  // Programmatically reset sheet to its default position
  resetSheet(): void {
    this._updateOffset(this._currentLayout.resetPoint);
  }

  // Programmatically collapse sheet to the bottom
  collapseSheet(): void {
    this._updateOffset(this.viewportHeight - COLLAPSED_HEIGHT);
  }

  handleClickBackground(): void {
    this.backgroundClicked.next();
  }
}
