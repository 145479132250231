import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentClick, ContentLink } from 'models';

@Component({
  standalone: false,
  selector: 'lib-link-card-view',
  templateUrl: './link-card-view.component.html',
  styleUrls: ['./link-card-view.component.less']
})
export class LinkCardViewComponent {
  @Output() cardClick = new EventEmitter<ContentClick>();
  @Input() link: ContentLink;
  @Input() blockTitle: string;

  get href(): string {
    const target = this.link?.url;
    const clickThrough = this.link?.urls?.clickThroughRaw;
    const type = this.link?.type;
    return clickThrough?.length
      ? clickThrough
      : type === 'email'
        ? `mailto:${target}`
        : type === 'phoneNumber'
          ? `sms:${target}`
          : type === 'callablePhoneNumber'
            ? `tel:${target}`
            : type === 'instagramDm'
              ? `https://ig.me/m/${target}`
              : this.link?.url;
  }

  handleClick(event) {
    this.cardClick.next({ content: this.link, event });
  }
}
