import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  standalone: false,
  selector: 'lib-norby-copy-block',
  templateUrl: './norby-copy-block.component.html',
  styleUrls: ['./norby-copy-block.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class NorbyCopyBlockComponent {
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() color?: string;
  @Input() titleStyles?: string;
  @Input() subtitleStyles?: string;
}
