import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-navigation-bar',
  templateUrl: './norby-navigation-bar.component.html',
  styleUrls: ['./norby-navigation-bar.component.less']
})
export class NorbyNavigationBarComponent {
  @Input() titleText: string;
  @Input() subtitle: string;

  @Output() onBackClicked = new EventEmitter<void>();

  constructor() {}

  handleBackClicked(): void {
    this.onBackClicked.emit();
  }
}
