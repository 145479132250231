import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';

import { plainToInstance } from 'class-transformer';
import { filter, map } from 'rxjs';

import {
  DEFAULT_BLOCK_PADDING,
  IImage,
  ISlug,
  FooterSingleSendBlock,
  SocialIconsSettings
} from 'models';
import {
  BaseComponent,
  IconService,
  NorbyInputComponent,
  rootMoreVertical
} from 'uikit';

@Component({
  standalone: false,
  selector: 'edit-email-footer-block',
  templateUrl: './edit-email-footer-block.component.html',
  styleUrls: ['./edit-email-footer-block.component.less']
})
export class EditEmailFooterBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @ViewChildren(NorbyInputComponent)
  inputElements!: QueryList<NorbyInputComponent>;

  @Input() block: FooterSingleSendBlock;
  @Input() slug: ISlug;
  @Input() isMobile: boolean = false;

  @Output() onUpdatedBlock: EventEmitter<FooterSingleSendBlock> =
    new EventEmitter<FooterSingleSendBlock>();

  formGroup: UntypedFormGroup;
  year = new Date().getFullYear();
  defaultSocialLinks: SocialIconsSettings[];
  showImageUploader = true;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _iconService: IconService
  ) {
    super();
    this._iconService.registerIcons([rootMoreVertical]);
  }

  get blockImageArray(): IImage[] {
    return this.block.image && Object.keys(this.block.image).length > 0
      ? [this.block?.image]
      : [];
  }

  get displayedSocialLinks() {
    return !this.block.socialLinks
      ? this.defaultSocialLinks
      : this.block.socialLinks;
  }

  ngOnInit(): void {
    this._initForm();
    if (!this.block.socialLinks) {
      this.defaultSocialLinks = Object.keys(
        this.slug.accountInfo?.links ?? []
      ).reduce(
        (arr, curr) => [
          ...arr,
          ...(this.slug.accountInfo.links[curr]
            ? [{ [curr]: this.slug.accountInfo.links[curr] }]
            : [])
        ],
        []
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        image: this.block?.image,
        imageEnabled: this.block?.imageEnabled,
        title: this.block?.title,
        copyright: this.block?.copyright,
        mailingAddress: this.block?.mailingAddress,
        paddingTop: this.block?.paddingTop,
        paddingRight: this.block?.paddingRight,
        paddingBottom: this.block?.paddingBottom,
        paddingLeft: this.block?.paddingLeft
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      image: [this.block.image ?? null],
      imageEnabled: [this.block.imageEnabled],
      title: [this.block.title ?? ''],
      copyright: [this.block.copyright ?? ''],
      mailingAddress: [this.block.mailingAddress ?? ''],
      paddingTop: [
        this.block?.paddingTop ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingRight: [
        this.block?.paddingRight ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingBottom: [
        this.block?.paddingBottom ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingLeft: [
        this.block?.paddingLeft ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) =>
          plainToInstance(FooterSingleSendBlock, {
            ...this.block,
            ...value
          })
        ),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }

  handleUpdatedLinks(socialLinks: SocialIconsSettings[]) {
    this.onUpdatedBlock.emit(
      plainToInstance(FooterSingleSendBlock, {
        ...this.block,
        socialLinks
      })
    );
  }

  handleImageUpdated(image: IImage[]) {
    this.formGroup.patchValue({
      image: image[0]
    });
  }

  handleChange(value: boolean) {
    this.showImageUploader = value;
  }
}
