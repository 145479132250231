import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

// Libs
import { slideIn } from 'customer-uikit';
import { Stage } from 'models';

@Component({
  standalone: false,
  selector: 'lib-email-login-form',
  templateUrl: './email-login-form.component.html',
  styleUrls: ['./email-login-form.component.less'],
  animations: [slideIn]
})
export class EmailLoginFormComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() isResendingCode: boolean;
  @Input() currentStage: Stage;

  @Output() handleEmailSubmit = new EventEmitter<{ email: string }>();
  @Output() handleCodeSubmit = new EventEmitter<{ verificationCode: string }>();
  @Output() handleResendCodeSubmit = new EventEmitter<void>();

  emailFormGroup: UntypedFormGroup;
  codeFormGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this._initForms();
  }

  submitEmail() {
    this.handleEmailSubmit.emit(this.emailFormGroup.value);
  }

  submitCode() {
    this.handleCodeSubmit.emit(this.codeFormGroup.value);
  }

  submitResendCode() {
    this.handleResendCodeSubmit.emit();
  }

  private _initForms() {
    this.emailFormGroup = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.codeFormGroup = this._formBuilder.group({
      verificationCode: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
      ]
    });
  }
}
