import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-flyout-option',
  templateUrl: './norby-flyout-option.component.html',
  styleUrls: ['./norby-flyout-option.component.less']
})
export class NorbyFlyoutOptionComponent {
  @Input() iconName: string;
  @Input() label: string;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  handleButtonClicked() {
    this.onClick.emit();
  }
}
