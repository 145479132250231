import { Component, Input, OnChanges, TemplateRef } from '@angular/core';
import { generateRandomName } from 'models';

@Component({
  standalone: false,
  selector: 'lib-user-row-view',
  templateUrl: './user-row-view.component.html',
  styleUrls: ['./user-row-view.component.less']
})
export class UserRowViewComponent implements OnChanges {
  @Input() avatarPosition: 'left' | 'right' = 'left';
  @Input() userId: string;
  @Input() avatarSize: 'default' | 'small' | 'large' = 'large';
  @Input() displayName: string | TemplateRef<any>;
  @Input() photoURL: string;
  @Input() subtitle: string | TemplateRef<any>;
  @Input() invert = false;

  color: string;
  private _randomName: string;

  ngOnChanges() {
    this._generateRandomName();
  }

  get actualName(): string | TemplateRef<any> {
    return this.displayName ?? this._randomName;
  }

  private _generateRandomName() {
    if (!this.userId) {
      this.color = 'gray';
      this._randomName = null;
      return;
    }

    const { label, color } = generateRandomName(this.userId);
    this.color = color;
    this._randomName = label;
  }
}
