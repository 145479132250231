import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { slideMotion } from '../../animations';

@Component({
  standalone: false,
  selector: 'root-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideMotion]
})
export class SubmenuComponent {
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  @Output() dropdownStateChanged = new EventEmitter<MouseEvent>();

  handleDropdownStateChanged(event: MouseEvent) {
    this.dropdownStateChanged.emit(event);
  }
}
