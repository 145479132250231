import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// Libs
import { IDropShadowValues } from 'models';
import { BaseComponent } from 'uikit';

@Component({
  standalone: false,
  selector: 'norby-drop-shadow-sliders',
  templateUrl: './norby-drop-shadow-sliders.component.html',
  styleUrls: ['./norby-drop-shadow-sliders.component.less']
})
export class NorbyDropShadowSlidersComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() values: IDropShadowValues;
  @Output() onShadowChange = new EventEmitter<string>();

  formGroup: UntypedFormGroup;
  updatedColor: string;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({
      h: [this.values?.h || 0],
      v: [this.values?.v || 0],
      blur: [this.values?.blur || 0],
      color: [this.values?.color]
    });

    this.formGroup.valueChanges
      .pipe(this.takeUntilDestroy)
      .subscribe((shadowValues: IDropShadowValues) => {
        const { h, v, blur, color } = shadowValues;
        const shadow = `${h ?? 0}px ${v ?? h ?? 0}px ${blur ?? 0}px ${
          this.updatedColor ?? color ?? '#000000'
        }`;
        this.onShadowChange.emit(shadow);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (!this.values || !this.formGroup) {
      return;
    }

    const dirty =
      this.values.h !== this.formGroup.value.h ||
      this.values.v !== this.formGroup.value.v ||
      this.values.blur !== this.formGroup.value.blur;

    this.updatedColor = this.values.color;

    if (dirty) {
      this.formGroup.patchValue(
        {
          h: this.values?.h || 0,
          v: this.values?.v || 0,
          blur: this.values?.blur || 0,
          color: this.values?.color
        },
        { emitEvent: false }
      );
    }
  }
}
