import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';

// 3rd party
import { NzModalRef } from 'ng-zorro-antd/modal';

// Libs
import {
  formatPhoneNumber,
  isPhoneNumberValid,
  VALID_COUNTRY_CODES
} from 'models';
import {
  CaptchaComponent,
  ErrorService,
  MessageService,
  MessageType
} from 'uikit';
import { AuthService } from '../../services';

@Component({
  standalone: false,
  selector: 'lib-add-user-phone-number',
  templateUrl: './add-user-phone-number.component.html',
  styleUrls: ['./add-user-phone-number.component.less']
})
export class AddUserPhoneNumberComponent
  extends CaptchaComponent
  implements OnInit
{
  form: UntypedFormGroup;
  isResendingCode = false;
  step: 'phone' | 'code' = 'phone';
  phonePlaceholder = '(123) 456-7890';

  readonly VALID_COUNTRY_CODES = VALID_COUNTRY_CODES;

  constructor(
    private _dialogRef: NzModalRef<AddUserPhoneNumberComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _message: MessageService,
    private _auth: AuthService,
    private _error: ErrorService
  ) {
    super();
  }

  get title(): string {
    return this.step === 'phone'
      ? 'Enter your phone number.'
      : 'Enter the verification code we just sent you.';
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.form = this._formBuilder.group(
      {
        phoneNumber: ['', Validators.required],
        countryCode: ['US', Validators.required],
        verificationCode: [
          undefined,
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6)
          ]
        ]
      },
      { validators: this._isPhoneNumberInvalid }
    );

    this.form.valueChanges.pipe(this.takeUntilDestroy).subscribe(() =>
      this._dialogRef.updateConfig({
        nzOkDisabled:
          (this.step === 'phone' && !!this._isPhoneNumberInvalid(this.form)) ||
          (this.step === 'code' && !this.form.valid)
      })
    );

    this._dialogRef.updateConfig({
      nzOnOk: () => {
        if (this.step === 'phone') {
          this._submitPhone();
        } else {
          this._submitCode();
        }

        return false;
      }
    });
  }

  // Form group validator to ensure phone number checks out
  private _isPhoneNumberInvalid(
    group: UntypedFormGroup
  ): ValidationErrors | null {
    const { phoneNumber, countryCode } = group.value;
    return isPhoneNumberValid(phoneNumber, countryCode);
  }

  handleCountryCodeChanged(code): void {
    const country = VALID_COUNTRY_CODES[code];
    this.phonePlaceholder = country?.placeholder;
  }

  async handleResendCode() {
    if (this._isPhoneNumberInvalid(this.form)) {
      return;
    }

    this.isResendingCode = true;

    try {
      await this._sendVerificationCode();
      this.isResendingCode = false;
    } catch (e) {
      this.isResendingCode = false;
      this._error.displayError(e);
    }
  }

  private async _submitPhone() {
    if (this._isPhoneNumberInvalid(this.form)) {
      return;
    }

    this._dialogRef.updateConfig({ nzOkLoading: true });

    try {
      if (await this._sendVerificationCode()) {
        this.step = 'code';

        this._dialogRef.updateConfig({
          nzOkText: 'Submit'
        });
      }
    } catch (e) {
      this._error.displayError(e);
    }

    this._dialogRef.updateConfig({ nzOkLoading: false });
  }

  private async _submitCode() {
    if (!this.form.valid) {
      return;
    }

    this._dialogRef.updateConfig({ nzOkLoading: true });

    const { phoneNumber, countryCode, verificationCode } = this.form.value;
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber, countryCode);

    try {
      await this._auth.completePhoneLogin({
        phoneNumber: formattedPhoneNumber,
        verificationCode: verificationCode.toString()
      });
      this._message.show({
        text: 'Successfully verified phone number',
        type: MessageType.SUCCESS
      });
      this._dialogRef.close(formattedPhoneNumber);
    } catch (e) {
      this._message.show({
        text: 'Error verifying your phone number',
        type: MessageType.ERROR
      });
    }

    this._dialogRef.updateConfig({ nzOkLoading: false });
  }

  private async _sendVerificationCode(): Promise<boolean> {
    const { countryCode, phoneNumber } = this.form.value;

    try {
      const captchaResponse = await this.executeCaptcha();

      return await this._auth.initiatePhoneSignInFlow(
        formatPhoneNumber(phoneNumber, countryCode),
        captchaResponse,
        true
      );
    } catch (e) {
      this._error.displayError(e);
    }
  }

  preserveOrder = (a, b): number => 0;
}
