import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2
} from '@angular/core';

@Component({
  standalone: false,
  selector: 'root-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.less']
})
export class TagComponent {
  @Input() tagStyle?: string;
  @Input() closeable?: boolean;
  @Input() color?: 'default' | 'success' | 'error' | 'processing' | 'warning' =
    'default';
  @Output() handleClose = new EventEmitter();

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef
  ) {}

  closeTag(e: MouseEvent): void {
    this.handleClose.emit(e);
    this._renderer.removeChild(
      this._renderer.parentNode(this._elementRef.nativeElement),
      this._elementRef.nativeElement
    );
  }
}
