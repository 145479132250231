import { Component, Inject, Input, OnChanges, Optional } from '@angular/core';

import {
  ContentLink,
  HeaderPageBlock,
  HeaderStyle,
  HeaderType,
  MenuOption,
  buildImageSrcset,
  getImageSrc
} from 'models';
import { USE_RAW_IMAGES } from '../../constants';
import { IconService, MockContentService } from '../../services';
import { rootMenu, rootX } from '../../../icons';

type ImageSet = {
  srcset?: string;
  src?: string;
};

@Component({
  standalone: false,
  selector: 'lib-header-view',
  templateUrl: './header-view.component.html',
  styleUrls: ['./header-view.component.less']
})
export class HeaderViewComponent implements OnChanges {
  @Input() block: HeaderPageBlock;
  @Input() dangleAvatar = false; // avatar hangs over edge of header banner

  bgImage: ImageSet;
  avatarImage: ImageSet;
  imageInlineStyle: string;
  menuOpen: boolean = false;

  private _bgSrc: string;
  private _bgSrcSet: string;
  private _avatarSrc: string;
  private _avatarSrcSet: string;

  constructor(
    @Optional() @Inject(USE_RAW_IMAGES) private _useRawImages,
    private _iconService: IconService,
    private _content: MockContentService
  ) {
    this._iconService.registerIcons([rootMenu, rootX]);
  }

  ngOnChanges(): void {
    if (this._useRawImages) {
      this._bgSrc = this.block?.backgroundImage?.url;
      this._bgSrcSet = '';

      this._avatarSrc = this.block?.avatarImage?.url;
      this._avatarSrcSet = '';
    } else {
      this._bgSrc = getImageSrc(
        this.block?.backgroundImage?.url,
        this.block?.responsiveBackgroundImage
      );
      this._bgSrcSet = buildImageSrcset(this.block?.responsiveBackgroundImage);

      this._avatarSrc = getImageSrc(
        this.block?.avatarImage?.url,
        this.block?.responsiveAvatarImage
      );
      this._avatarSrcSet = buildImageSrcset(this.block?.responsiveAvatarImage);
    }

    this.bgImage = {
      src: this._bgSrc,
      srcset: this._bgSrcSet
    };

    this.avatarImage = {
      src: this._avatarSrc,
      srcset: this._avatarSrcSet
    };

    this.imageInlineStyle =
      this.block?.maskUrl && this.style === 'creator'
        ? `-webkit-mask-image: url(${this.block.maskUrl}); mask-image: url(${this.block.maskUrl}); width: ${this.sizeInPixels}; height: ${this.sizeInPixels}; -webkit-mask-size: ${this.sizeInPixels} ${this.sizeInPixels}`
        : '';

    if (this.menuOptions?.length > 0 && this.shouldDisplayMenuIcon) {
      this.menuOptions.forEach(async (menuItem: any) => {
        const res: ContentLink = await this._content.getContent(
          menuItem.contentId
        );
        menuItem.url = res?.urls?.clickThroughRaw || 'javascript:void(0);';
        if (!menuItem?.title) {
          menuItem.title = res?.title || '';
        }
      });
    }
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  get alt(): string {
    return this.block?.avatarImage?.altText || 'Logo';
  }

  get height(): string {
    return `${this.block?.height || 120}px`;
  }

  get sizeInPixels(): string {
    return `${(this.imageSize * this.block.height) / 100}px`;
  }

  get sizeInPixelsWithMenu(): string {
    return `${(this.imageSize * this.block.height) / 100 + 36}px`;
  }

  get style(): HeaderStyle {
    return this.block?.style ?? 'brand';
  }

  get headerType(): HeaderType {
    return this.block?.headerType || 'imageOnly';
  }

  get shouldDisplayMenuOptions(): boolean {
    return this.headerType == 'imageAndMenu' && this.menuOpen;
  }

  get shouldDisplayMenuIcon(): boolean {
    return this.headerType == 'imageAndMenu';
  }

  get backgroundColor(): string {
    return this.block?.backgroundGradient ?? this.block?.backgroundColor;
  }

  get href(): string {
    return this.block?.avatarClickThroughUrl ?? 'javascript:void(0);';
  }

  get isRelative(): boolean {
    return this.block?.avatarClickThroughUrlType === 'collection';
  }

  get isMenuCenter(): boolean {
    return this.menuAlignment == 'center';
  }

  get menuAlignment(): string {
    return this.block?.menuAlignment || 'right';
  }

  get menuOptions(): MenuOption[] {
    return this.block?.menuOptions ?? [];
  }

  get imageSize(): number {
    return this.block?.imageSize ?? 100;
  }

  get hamburgerMenuColor(): string {
    return this.block?.hamburgerMenuColor ?? '#000000';
  }
}
