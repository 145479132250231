import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-helpertext',
  templateUrl: './norby-helpertext.component.html',
  styleUrls: ['./norby-helpertext.component.less']
})
export class NorbyHelpertextComponent {
  @Input() helperText?: string;
  @Input() errorText?: string;
}
