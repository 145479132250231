import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges
} from '@angular/core';

// Libs
import { BaseComponent } from 'uikit';
import { ContactView } from 'models';

@Component({
  standalone: false,
  selector: 'app-contact-list-table-view',
  templateUrl: './contact-list-table-view.component.html',
  styleUrls: ['./contact-list-table-view.component.less']
})
export class ContactListTableViewComponent extends BaseComponent {
  @Input() lists: Array<ContactView>;
  @Input() isLoading: boolean;
  @Input() hasNextPage: boolean;

  @Output() onLoadNext = new EventEmitter<void>();
  @Output() onClickList = new EventEmitter<ContactView>();

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  trackBy(idx: number, item: ContactView) {
    return item?.id;
  }

  handleClickList(index: number) {
    this.onClickList.emit(this.lists[index]);
  }

  handleLoadNext() {
    this.onLoadNext.emit();
  }
}
