import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';

// 3rd party
import { NzModalRef } from 'ng-zorro-antd/modal';

// Libs
import { ModalComponent } from 'uikit';
import {
  SegmentFilter,
  SegmentContactPreview,
  SegmentFilterGroup,
  ContactList,
  EditFilterGroupEvent
} from 'models';
import { plainToInstance } from 'class-transformer';

@Component({
  standalone: false,
  selector: 'app-build-contact-list',
  templateUrl: './build-contact-list-filters.component.html',
  styleUrls: ['./build-contact-list-filters.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildContactListFiltersComponent
  extends ModalComponent
  implements OnInit
{
  list: ContactList;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _modalRef: NzModalRef<BuildContactListFiltersComponent>
  ) {
    super();
  }

  get filters(): SegmentFilter[] {
    return this.list.segment.filterGroups.map((group) => group.filters[0]);
  }

  ngOnInit(): void {
    this._modalRef.updateConfig({
      nzOnOk: () => this._saveAndClose()
    });
  }

  handleUpdateFilterGroup(editFilterGroupEvent: EditFilterGroupEvent) {
    if (!editFilterGroupEvent.filter) {
      // Update filter operator on an existing filter group
      // Currently unused
      if (this.list.segment.filterGroups[editFilterGroupEvent.groupIndex]) {
        this.list.segment.updateFilterGroupOperatorAtIndex(
          editFilterGroupEvent.groupIndex,
          // editFilterGroupEvent.filterOperator
          'AND'
        );
        this._cdr.detectChanges();
      }
      return;
    }

    //Add new filter group with single filter if group doesn't exist
    if (!this.list.segment.filterGroups[editFilterGroupEvent.groupIndex]) {
      this.list.segment.updateFilterGroupAtIndex(
        editFilterGroupEvent.groupIndex,
        plainToInstance(SegmentFilterGroup, {
          // filterOperator: editFilterGroupEvent.filterOperator,
          filterOperator: 'AND',
          filters: [editFilterGroupEvent.filter]
        })
      );
    } else {
      this.list.segment.filterGroups[editFilterGroupEvent.groupIndex].addFilter(
        editFilterGroupEvent.filter
      );
    }

    this._cdr.detectChanges();
  }

  handleDeleteFilter(editFilterGroupEvent: EditFilterGroupEvent) {
    if (!this.list.segment.filterGroups[editFilterGroupEvent.groupIndex]) {
      return;
    }

    const index = this.list.segment.filterGroups[
      editFilterGroupEvent.groupIndex
    ].filters.findIndex((f) => f.hash === editFilterGroupEvent.filter.hash);

    if (index > -1) {
      this.list.segment.filterGroups[
        editFilterGroupEvent.groupIndex
      ].removeFilterAtIndex(index);

      if (
        this.list.segment.filterGroups[editFilterGroupEvent.groupIndex].filters
          .length === 0
      ) {
        this.list.segment.removeFilterGroupAtIndex(
          editFilterGroupEvent.groupIndex
        );
      }

      this._cdr.detectChanges();
    }
  }

  handleAddContact(contact: SegmentContactPreview) {
    this.list.segment.addIncludedContact(contact);
    this._cdr.detectChanges();
  }

  handleExcludeContact(contact: SegmentContactPreview) {
    this.list.segment.addExcludedContact(contact);
    this._cdr.detectChanges();
  }

  handleRemoveIncludedContact(contactId: string) {
    this.list.segment.removeIncludedContact(contactId);
    this._cdr.detectChanges();
  }

  handleRemoveExcludedContact(contactId: string) {
    this.list.segment.removeExcludedContact(contactId);
    this._cdr.detectChanges();
  }

  private _saveAndClose() {
    this._modalRef.close(this.list);
  }
}
