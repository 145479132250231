import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  QueryList,
  SimpleChanges,
  HostBinding
} from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  UrlSegmentGroup,
  UrlSegment
} from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

/*

Child link matching directive that matches routes but ignores query params
Adapted from nz-menu-item directive and Angular source code

See:
- https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/menu/menu-item.directive.ts
- https://github.com/angular/angular/blob/master/packages/router/src/url_tree.ts
- https://github.com/angular/angular/blob/master/packages/router/src/router.ts

Existing `nzMatchRouterExact` input on nzMenuItem directive will exact match on query params
as well as segment groups, which is undesirable in some cases

For the admin dash, we use query params to control the tabs (upcoming/past/drafts),
and using exact matching causes the Dashboard button on the left side to become deactivated
when the user navigates to past events, drafts, etc

Conversely, *not* including exact matching causes Dashboard to remain lit up even when the user
navigates to /admin/audience, for instance, since that's still technically a segment match

This directive exact matches segments (e.g. /admin) but ignores diverging query params in order
to provide expected behavior in these circumstances

*/

@Directive({ standalone: false, selector: '[libMatchRouterIgnoreParams]' })
export class MatchRouterIgnoreParamsDirective
  implements OnChanges, OnDestroy, AfterContentInit
{
  private destroy$ = new Subject<void>();
  selected$ = new Subject<boolean>();
  @Input() @HostBinding('class.ant-menu-item-selected') nzSelected = false;
  @ContentChildren(RouterLink, { descendants: true })
  listOfRouterLink!: QueryList<RouterLink>;
  @ContentChildren(RouterLink, { descendants: true })
  listOfRouterLinkWithHref!: QueryList<RouterLink>;

  private updateRouterActive(): void {
    if (
      !this.listOfRouterLink ||
      !this.listOfRouterLinkWithHref ||
      !this.router ||
      !this.router.navigated
    )
      return;

    Promise.resolve().then(() => {
      const hasActiveLinks = this.hasActiveLinks();
      if (this.nzSelected !== hasActiveLinks) {
        this.nzSelected = hasActiveLinks;
        this.setSelectedState(this.nzSelected);
        this.cdr.markForCheck();
      }
    });
  }

  private hasActiveLinks(): boolean {
    const isActiveCheckFn = this.isLinkActive(this.router);
    return (
      (this.routerLink && isActiveCheckFn(this.routerLink)) ||
      (this.routerLinkWithHref && isActiveCheckFn(this.routerLinkWithHref)) ||
      this.listOfRouterLink.some(isActiveCheckFn) ||
      this.listOfRouterLinkWithHref.some(isActiveCheckFn)
    );
  }

  setSelectedState(value: boolean): void {
    this.nzSelected = value;
    this.selected$.next(value);
  }

  private isLinkActive(
    router: Router
  ): (link: RouterLink | RouterLink) => boolean {
    return (link: RouterLink | RouterLink) => {
      const currentUrlTree = router.createUrlTree([]);
      const equalSegments = equalSegmentGroups(
        currentUrlTree.root,
        link.urlTree.root
      );
      return equalSegments;
    };
  }

  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() private routerLink?: RouterLink,
    @Optional() private routerLinkWithHref?: RouterLink,
    @Optional() private router?: Router
  ) {
    if (router) {
      this.router.events
        .pipe(
          takeUntil(this.destroy$),
          filter((e) => e instanceof NavigationEnd)
        )
        .subscribe(() => {
          this.updateRouterActive();
        });
    }
  }

  ngAfterContentInit(): void {
    this.listOfRouterLink.changes
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateRouterActive());
    this.listOfRouterLinkWithHref.changes
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateRouterActive());
    this.updateRouterActive();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.nzSelected) {
      this.setSelectedState(this.nzSelected);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

function equalSegmentGroups(
  container: UrlSegmentGroup,
  containee: UrlSegmentGroup
): boolean {
  if (!equalPath(container.segments, containee.segments)) return false;
  if (container.numberOfChildren !== containee.numberOfChildren) return false;
  for (const c in containee.children) {
    if (!container.children[c]) return false;
    else if (!equalSegmentGroups(container.children[c], containee.children[c]))
      return false;
  }
  return true;
}

function equalPath(as: UrlSegment[], bs: UrlSegment[]): boolean {
  if (as.length !== bs.length) return false;
  return as.every((a, i) => a.path === bs[i].path);
}
