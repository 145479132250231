import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-fab',
  templateUrl: './norby-fab.component.html',
  styleUrls: ['./norby-fab.component.less']
})
export class NorbyFabComponent {
  @Input() iconName: string;
  @Input() commandName?: string;
  @Input() isDisabled: boolean = false;
  @Output() onButtonClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  handleButtonClick(): void {
    this.onButtonClicked.emit(this.commandName || this.iconName);
  }
}
