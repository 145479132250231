import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { ENDPOINTS, ApiSurfaces, YoutubePageBlock, uuidv4 } from 'models';

import { MockApiService } from '../../services';
import {
  DO_NOT_TRACK_ANALYTICS,
  YOUTUBE_IFRAME_API_SELECTOR,
  YOUTUBE_IFRAME_ID_PREFIX
} from '../../constants';

@Component({
  standalone: false,
  selector: 'lib-youtube-embed-view',
  templateUrl: './youtube-embed-view.component.html',
  host: { ngSkipHydration: 'true' }
})
export class YoutubeEmbedViewComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() block: YoutubePageBlock;
  @Input() pageId: string;

  @ViewChild('iframePlaceholder') iframePlaceholder: ElementRef;

  iframeId: string;
  private _player: YT.Player;

  @ViewChild('elementRef', { static: true }) element: ElementRef;

  constructor(
    @Inject(DO_NOT_TRACK_ANALYTICS)
    @Optional()
    private _doNotTrackAnalytics: boolean,
    @Inject(DOCUMENT) private _document: any,
    private _renderer: Renderer2,
    private _api: MockApiService
  ) {
    this.iframeId = `${YOUTUBE_IFRAME_ID_PREFIX}${uuidv4()}`;
  }

  async ngAfterViewInit(): Promise<void> {
    await this._loadYouTubePlayer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { previousValue, currentValue } = changes.block || {};
    if (previousValue && currentValue) {
      const previousOptions = this._buildIframeOptions(previousValue);
      const currentOptions = this._buildIframeOptions(currentValue);
      if (!this._embedOptionsAreEqual(previousOptions, currentOptions)) {
        this._refreshEmbed(currentOptions);
      }
    }
  }

  ngOnDestroy(): void {
    this._player?.destroy();
  }

  private _embedOptionsAreEqual(a: YT.PlayerOptions, b: YT.PlayerOptions) {
    for (const [key, val] of Object.entries(a)) {
      if (key !== 'events' && b[key] !== val) return false;
    }
    return true;
  }

  private _refreshEmbed(options: YT.PlayerOptions) {
    this._player.setSize(+options.width, +options.height);
    this._player.loadVideoById(options.videoId);
  }

  private _buildIframeOptions(block: YoutubePageBlock): YT.PlayerOptions {
    return {
      height: block.height ?? 315,
      width: block.width ?? 560,
      videoId: this._extractVideoId(block.url),
      events: {
        onStateChange: (e) => this._handlePlayerStateChange(e)
      },
      host: 'https://www.youtube-nocookie.com'
    };
  }

  private _extractVideoId(url: string): string | null {
    try {
      const youtubeUrlParsed = new URL(url);

      if (url.match(/youtube.com\/shorts/)) {
        return youtubeUrlParsed.pathname.replace('/shorts/', '');
      }

      const videoId = url.match(/youtube/)
        ? youtubeUrlParsed.searchParams.get('v')
        : youtubeUrlParsed.pathname.replace('/', '');

      return videoId;
    } catch (e) {}

    return null;
  }

  private _handlePlayerStateChange(event: any) {
    const playerStatus = event.data;
    if (playerStatus == 1) {
      this._recordYouTubePlay();
    }
  }

  private async _loadYouTubePlayer() {
    const youTubeApi = await this._getYouTubeApi();

    try {
      this._player = new youTubeApi.Player(
        this.iframeId,
        this._buildIframeOptions(this.block)
      );
    } catch (e) {}
  }

  private _recordYouTubePlay() {
    if (this._doNotTrackAnalytics) return;

    const videoId = this._extractVideoId(this.block.url);
    const shortUrl = `https://youtu.be/${videoId}`;
    this._api
      .post(ApiSurfaces.END_USER, ENDPOINTS.analytics.trackEntityInteraction, {
        entityType: 'collection',
        entityId: this.pageId,
        interactionType: 'youtubePlay',
        interactionValue: videoId,
        interactionMetadata: { url: shortUrl },
        domReferrer: this._document.referrer
      })
      .catch();
  }

  private async _getYouTubeApi() {
    if (window[YOUTUBE_IFRAME_API_SELECTOR])
      return window[YOUTUBE_IFRAME_API_SELECTOR];

    const scriptId = `${YOUTUBE_IFRAME_API_SELECTOR}-script`;
    const youtubeScript = this._document.querySelector(`#${scriptId}`);
    if (!youtubeScript) {
      (window as any).onYouTubeIframeAPIReady = () => {
        window[YOUTUBE_IFRAME_API_SELECTOR] = (window as any).YT;
      };

      const newScript = this._renderer.createElement('script');
      this._renderer.setAttribute(newScript, 'id', scriptId);
      this._renderer.setAttribute(
        newScript,
        'src',
        'https://www.youtube.com/iframe_api'
      );

      const firstScript = this._document.getElementsByTagName('script')[0];
      this._renderer.insertBefore(
        this._renderer.parentNode(firstScript),
        newScript,
        firstScript
      );
    }

    await new Promise((resolve) => setTimeout(resolve, 10));
    return this._getYouTubeApi();
  }
}
