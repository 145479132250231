import { coerceCssPixelValue } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

export interface SkeletonTitle {
  width?: number | string;
}

export interface SkeletonParagraph {
  rows?: number;
  width?: number | string;
}

@Component({
  standalone: false,
  selector: 'root-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonComponent implements OnInit, OnChanges {
  @Input() title: SkeletonTitle | boolean = true;
  @Input() paragraph: SkeletonParagraph | boolean = true;

  @HostBinding('class')
  elementClass = 'w-full table';

  titleProps!: SkeletonTitle;
  paragraphProps!: SkeletonParagraph;
  rowsList: number[] = [];
  widthList: Array<number | string> = [];

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._updateProps();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title || changes.paragraph) {
      this._updateProps();
    }
  }

  toCSSUnit(value: number | string = ''): string {
    return coerceCssPixelValue(value);
  }

  private _getTitleProps(): SkeletonTitle {
    const hasParagraph = !!this.paragraph;
    let width = '';
    if (hasParagraph) {
      width = '38%';
    }
    return { width, ...this._getProps(this.title) };
  }

  private _getParagraphProps(): SkeletonParagraph {
    const hasTitle = !!this.title;
    const basicProps: SkeletonParagraph = {};

    basicProps.width = '61%';

    if (hasTitle) {
      basicProps.rows = 3;
    } else {
      basicProps.rows = 2;
    }

    return { ...basicProps, ...this._getProps(this.paragraph) };
  }

  private _getProps<T>(prop: T | boolean | undefined): T | {} {
    return prop && typeof prop === 'object' ? prop : {};
  }

  private _getWidthList(): Array<number | string> {
    const { width, rows } = this.paragraphProps;
    let widthList: Array<string | number> = [];
    if (width && Array.isArray(width)) {
      widthList = width;
    } else if (width && !Array.isArray(width)) {
      widthList = [];
      widthList[rows! - 1] = width;
    }
    return widthList;
  }

  private _updateProps(): void {
    this.titleProps = this._getTitleProps();
    this.paragraphProps = this._getParagraphProps();
    this.rowsList = [...Array(this.paragraphProps.rows)];
    this.widthList = this._getWidthList();
    this._cdr.markForCheck();
  }
}
