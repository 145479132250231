import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  Optional
} from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-icon',
  templateUrl: './norby-icon.component.html',
  styleUrls: ['./norby-icon.component.less']
})
export class NorbyIconComponent {
  private _svgIcon: SVGElement;

  @Input()
  @HostBinding('class')
  size: 'small' | 'large';

  @Input()
  @HostBinding('name')
  set name(name: string) {
    if (this._svgIcon) {
      this._element.nativeElement.removeChild(this._svgIcon);
    }

    // Dynamic Import Expressions
    // Dynamic import expressions are a new feature and part of ECMAScript that allows users to asynchronously request a module at any arbitrary point in your program.
    // This means that you can conditionally and lazily import other modules and libraries. This feature was introduced on TypeScript 2.4

    import(
      'projects/uikit/src/icons/build/root-' +
        name.split('_').join('-') +
        '.icon'
    ).then((icon) => {
      if (
        this._svgIcon &&
        this._element.nativeElement.contains(this._svgIcon)
      ) {
        this._element.nativeElement.removeChild(this._svgIcon);
      }

      const svgData = icon[Object.keys(icon)[0]].data;
      this._svgIcon = this._svgElementFromString(svgData);
      this._element.nativeElement.appendChild(this._svgIcon);
    });
  }

  constructor(
    private _element: ElementRef,
    @Optional() @Inject(DOCUMENT) private _document: any
  ) {}

  private _svgElementFromString(svgContent: string): SVGElement {
    const div = this._document.createElement('DIV');
    div.innerHTML = svgContent;

    return (
      div.querySelector('svg') ||
      this._document.createElementNS('http://www.w3.org/2000/svg', 'path')
    );
  }
}
