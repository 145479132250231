import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { BaseComponent } from 'uikit';

@Directive({ standalone: false, selector: '[hoverDirective]' })
export class HoverDirective extends BaseComponent implements OnChanges {
  @Input() listener;
  @Input() targetElem; // optional

  constructor(public elementRef: ElementRef) {
    super();
  }

  ngOnChanges() {
    super.ngOnChanges();
    // set opacity on the target element if it's passed in otherwise fallback to the host element
    const visibleElement = this.targetElem ?? this.elementRef.nativeElement;

    this.listener?.pipe(this.takeUntilChanges)?.subscribe((event: boolean) => {
      if (event) {
        visibleElement.style.opacity = 1;
        visibleElement.classList.add('hover-active');
      } else {
        visibleElement.style.opacity = '';
        visibleElement.classList.remove('hover-active');
      }
    });
  }
}
