import { Component, OnInit } from '@angular/core';

// 3rd party
import { tap } from 'rxjs/operators';

// App
import { AuthService } from 'shared';
import { BaseComponent } from 'uikit';
import { SuperAdminService } from '@super-admin/services';

@Component({
  standalone: false,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent extends BaseComponent implements OnInit {
  userIsLoggedIn = false;
  isLoadingUser = true;
  isEditor = false;
  isDeveloper = false;

  constructor(
    private _super: SuperAdminService,
    private _auth: AuthService // Kickstart auth
  ) {
    super();
  }

  ngOnInit(): void {
    this._auth.user$
      .pipe(
        tap((u) => (this.isLoadingUser = !u)),
        this.takeUntilDestroy
      )
      .subscribe((user) => (this.userIsLoggedIn = user && !user.isAnonymous));

    this._super
      .userIsGlobalEditor$()
      .pipe(this.takeUntilDestroy)
      .subscribe((isEditor) => (this.isEditor = isEditor));

    this._super
      .userIsGlobalDeveloper$()
      .pipe(this.takeUntilDestroy)
      .subscribe((isDeveloper) => (this.isDeveloper = isDeveloper));
  }

  get canViewSuper(): boolean {
    return this.isEditor || this.isDeveloper;
  }

  attemptLogin(): void {
    this._super.launchLoginFlow();
  }

  logout(): void {
    this._super.logoutAndGoHome();
  }
}
