import { Component, EventEmitter, Output } from '@angular/core';
import { CheckboxComponent } from './checkbox.component';

@Component({
  standalone: false,
  selector: 'root-checkbox-wrapper',
  templateUrl: './checkbox-wrapper.component.html'
})
export class CheckboxWrapperComponent {
  @Output() onHandleChange = new EventEmitter<any[]>();
  private _checkboxList: CheckboxComponent[] = [];

  addCheckbox(value: CheckboxComponent): void {
    this._checkboxList.push(value);
  }

  removeCheckbox(value: CheckboxComponent): void {
    this._checkboxList.splice(this._checkboxList.indexOf(value), 1);
  }

  onChange(): void {
    const listOfCheckedValue = this._checkboxList
      .filter((item) => item.checked)
      .map((item) => item.value);
    this.onHandleChange.emit(listOfCheckedValue);
  }
}
