import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';

// 3rd party
import { distinctUntilChanged } from 'rxjs';

// Libs
import { BaseComponent } from 'uikit';
import {
  ContactListSearchResults,
  ContactSearchResults,
  ContentSearchResults,
  DeliveryType,
  EditFilterGroupEvent,
  FilterGroupControlType,
  IContact,
  IContactSearchResultNode,
  LandingPage,
  Segment,
  SegmentContactPreview,
  SegmentFilter,
  SingleSendSearchResults,
  generateRandomName,
  uuidv4
} from 'models';

@Component({
  standalone: false,
  selector: 'norby-filters-build-view',
  templateUrl: './norby-filters-build-view.component.html',
  styleUrls: ['./norby-filters-build-view.component.less']
})
export class NorbyFiltersBuildViewComponent
  extends BaseComponent
  implements OnInit
{
  @Input() contentFilterOptions: ContentSearchResults[];
  @Input() contactListFilterOptions: ContactListSearchResults[];
  @Input() includedContactOptions: ContactSearchResults[];
  @Input() excludedContactOptions: ContactSearchResults[];
  @Input() engagementFilterOptions: SingleSendSearchResults[];
  @Input() landingPageFilterOptions: LandingPage[];
  @Input() tagText: string;
  @Input() isDisabled: boolean;
  @Input() segment: Segment;
  @Input() deliveryType: DeliveryType;
  @Input() isSearchingFilter: boolean;
  @Input() isSearchingExcludeContact: boolean;
  @Input() isSearchingIncludeContact: boolean;
  @Input() isEventUpdates: boolean = false;
  @Input() isSendBuilder: boolean = false;

  @Output() onSearchInputChange = new EventEmitter<string>();
  @Output() onExcludedContactInputChange = new EventEmitter<string>();
  @Output() onIncludedContactInputChange = new EventEmitter<string>();
  @Output() onUpdateFilterGroup = new EventEmitter<EditFilterGroupEvent>();
  @Output() onDeleteFilter = new EventEmitter<EditFilterGroupEvent>();
  @Output() onAddContact = new EventEmitter<SegmentContactPreview>();
  @Output() onRemoveIncludedContact = new EventEmitter<string>();
  @Output() onRemoveExcludedContact = new EventEmitter<string>();
  @Output() onExcludeContact = new EventEmitter<SegmentContactPreview>();

  mouseOnChild: boolean = false;
  formGroup: UntypedFormGroup;
  filterGroupsForm: UntypedFormGroup;
  filterGroupsControls: Array<FilterGroupControlType> = [];
  isFilterListVisible: boolean = true;
  editingGroupIndex: number;

  private _editingGroupControl: FilterGroupControlType;
  private _cashedQuery: string;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  private _initForm() {
    const filterGroupsArray = this.segment?.filterGroups.slice() ?? [];

    this.filterGroupsControls = filterGroupsArray.map<FilterGroupControlType>(
      (group, idx) => {
        const id = `f${idx}`;
        return {
          id,
          controlInstance: `group${id}`,
          isNew: false,
          filters: group.filters.slice()
        };
      }
    );

    this.filterGroupsForm = this._formBuilder.group(
      filterGroupsArray.reduce((prev, curr, index) => {
        return {
          ...prev,
          [this.filterGroupsControls[index]?.controlInstance]:
            this._formBuilder.group({
              searchInput: ['', []]
              // filterOperator: [
              //   curr?.filterOperator || 'AND',
              //   Validators.required
              // ]
            })
        };
      }, {})
    );

    this.formGroup = this._formBuilder.group({
      filterGroups: this.filterGroupsForm,
      includedContactInput: ['', []],
      excludedContactInput: ['', []]
    });

    this.formGroup.controls['filterGroups'].valueChanges
      .pipe(distinctUntilChanged(), this.takeUntilDestroy)

      .subscribe((value) => {
        const query =
          value[this._editingGroupControl?.controlInstance]?.searchInput;
        if (this._cashedQuery !== query) {
          this.isFilterListVisible = query?.length;
          this.onSearchInputChange.emit(query);
          this._cashedQuery = query;
        }
      });

    this.formGroup.controls['excludedContactInput'].valueChanges
      .pipe(distinctUntilChanged(), this.takeUntilDestroy)
      .subscribe((value) => this.onExcludedContactInputChange.emit(value));

    this.formGroup.controls['includedContactInput'].valueChanges
      .pipe(distinctUntilChanged(), this.takeUntilDestroy)
      .subscribe((value) => this.onIncludedContactInputChange.emit(value));
  }

  contactListInfoDisplay(contact: SegmentContactPreview) {
    switch (this.deliveryType) {
      case 'email':
        return contact.primaryEmail ?? '';
      case 'sms':
        return contact.primaryPhoneNumber ?? '';
      default:
        return contact.primaryEmail ?? '';
    }
  }

  contactSearchResultInfoDisplay(option: ContactSearchResults) {
    switch (this.deliveryType) {
      case 'email':
        return option.highlightedMetadata ?? option.contact.primary_email ?? '';
      case 'sms':
        return option.contact.primary_phone_number ?? '';
      default:
        return '';
    }
  }

  get sendToLabel(): string {
    return this.deliveryType ? 'Send to' : 'Search';
  }

  get dontSendToLabel(): string {
    return this.deliveryType ? "Don't send to" : "Don't include";
  }

  get primarySearchHelperText(): string {
    return this.isEventUpdates
      ? 'Search for an event.'
      : 'Type a name, email, or tag, or search for an event or signup.';
  }

  get includedContactHelperText() {
    return `Include these contacts in your ${
      this.isSendBuilder ? 'send' : 'list'
    }.`;
  }

  get excludedContactHelperText() {
    return `Exclude these contacts from your ${
      this.isSendBuilder ? 'send' : 'list'
    }.`;
  }

  getNameForContact(contact: IContact) {
    return contact?.name ?? generateRandomName(contact?.id).label;
  }

  handleChildDropdownMouseEvent(event) {
    this.mouseOnChild = event;
  }

  isContactOptedIn(option: ContactSearchResults) {
    switch (this.deliveryType) {
      case 'email':
        return option.contact.has_opted_into_email_marketing;
      case 'sms':
        return option.contact.has_opted_into_sms_marketing;
      default:
        return true;
    }
  }

  handleAddFilter(filter: SegmentFilter) {
    this._editingGroupControl.filters.push(filter);
    this.isFilterListVisible = false;

    this.filterGroupsForm
      .get(this._editingGroupControl.controlInstance)
      .patchValue({ searchInput: '' });

    this.onUpdateFilterGroup.emit({
      filter,
      groupIndex: this.editingGroupIndex,
      // filterOperator:
      //   this.filterGroupsForm.get(this._editingGroupControl?.controlInstance)
      //     ?.value?.filterOperator ?? 'AND'
      filterOperator: 'AND'
    });
  }

  handleDeleteFilter(
    filter: SegmentFilter,
    groupIndex: number,
    control: FilterGroupControlType
  ) {
    if (this.segment.filterGroups[groupIndex].filters.length < 2) {
      const index =
        this.filterGroupsControls?.length > 1
          ? this.filterGroupsControls?.indexOf(control)
          : 0;
      this.filterGroupsControls?.splice(index, 1);
      this.filterGroupsForm.markAsDirty();
      this.filterGroupsForm?.removeControl(control?.controlInstance);
    } else {
      const index = control.filters?.indexOf(filter);
      control.filters?.splice(index, 1);
      this.filterGroupsForm.markAsDirty();
    }
    this.onDeleteFilter.emit({
      filter,
      groupIndex,
      filterOperator: 'AND'
    });
  }

  handleAddFilterRule(editingIndex: number, control: FilterGroupControlType) {
    this.editingGroupIndex = editingIndex;
    this._editingGroupControl = control;
  }

  handleAddFilterGroup() {
    const id = uuidv4();
    const control: FilterGroupControlType = {
      id,
      controlInstance: `group${id}`,
      isNew: true,
      filters: []
    };

    const length = this.filterGroupsControls?.push(control);
    this.editingGroupIndex = length - 1;
    this._editingGroupControl = control;

    this.filterGroupsForm.addControl(
      this.filterGroupsControls[length - 1]?.controlInstance,
      this._formBuilder.group({
        searchInput: ['', []]
        // filterOperator: ['AND', Validators.required]
      })
    );
  }

  // handleUpdateFilterOperator(
  //   value: string,
  //   groupIndex: number,
  //   control: FilterGroupControlType
  // ) {
  //   this.editingGroupIndex = groupIndex;
  //   this._editingGroupControl = control;

  //   this.onUpdateFilterGroup.emit({
  //     groupIndex,
  //     filterOperator: value as FilterGroupOperator
  //   });
  // }

  handleAddContact(option: ContactSearchResults) {
    if (this.isContactOptedIn(option)) {
      const addedContact = {
        contactId: option.contact.id,
        displayName: option.contact.display_name,
        name: option.contact.name,
        nickname: option.contact.nickname,
        primaryEmail: option.contact.primary_email,
        primaryPhoneNumber: option.contact.primary_phone_number
      };
      this.onAddContact.emit(addedContact);
    }
  }

  handleExcludeContact(contact: IContactSearchResultNode) {
    const excludedContact = {
      contactId: contact.id,
      displayName: contact.display_name,
      nickname: contact.nickname,
      name: contact.name,
      primaryEmail: contact.primary_email,
      primaryPhoneNumber: contact.primary_phone_number
    };
    this.onExcludeContact.emit(excludedContact);
  }

  handleRemoveIncludedContact(contactId: string) {
    this.onRemoveIncludedContact.emit(contactId);
  }

  handleRemoveExcludedContact(contactId: string) {
    this.onRemoveExcludedContact.emit(contactId);
  }

  handleFilterListClosed() {
    this.isFilterListVisible = false;
  }

  handleInputFocused() {
    this.isFilterListVisible = true;
  }
}
