import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';

// 3rd party
import { NzModalRef } from 'ng-zorro-antd/modal';

// Lib
import { ModalComponent } from 'uikit';
import {
  ContactListColumn,
  VISIBLE_CONTACT_LIST_COLUMNS,
  CONTACT_LIST_COLUMN_NAME
} from 'models';

@Component({
  standalone: false,
  selector: 'app-edit-contact-columns',
  templateUrl: './edit-contact-columns.component.html',
  styleUrls: ['./edit-contact-columns.component.less']
})
export class EditContactColumnsComponent
  extends ModalComponent
  implements OnInit
{
  columns: ContactListColumn[];
  hiddenColumns: ContactListColumn[];

  constructor(
    private _cdr: ChangeDetectorRef,
    private _modalRef: NzModalRef<EditContactColumnsComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this._modalRef.updateConfig({
      nzOnOk: () => this._modalRef.close(this.columns)
    });

    // Copy the visible columns array since arrays are passed by reference
    this.columns = this.columns?.slice() ?? [];

    // Initialize the hidden columns list as the set of all possible columns
    // less the visible columns
    this.hiddenColumns = VISIBLE_CONTACT_LIST_COLUMNS.filter(
      (col) => this.columns.findIndex((c) => c === col) === -1
    );
  }

  getNameForColumn(column: ContactListColumn): string {
    return CONTACT_LIST_COLUMN_NAME[column] || '';
  }

  handleDropColumn(event: CdkDragDrop<ContactListColumn[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this._cdr.markForCheck();
  }

  handleDeleteColumn(value: ContactListColumn, index: number): void {
    this.columns.splice(index, 1);
    this.hiddenColumns.unshift(value);
    this._cdr.markForCheck();
  }

  handleAddColumn(value: ContactListColumn, index: number): void {
    this.columns.unshift(value);
    this.hiddenColumns.splice(index, 1);
    this._cdr.markForCheck();
  }
}
