import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import { IImage } from 'models';
import { MockFileUploadService } from '../../../services';

@Component({
  standalone: false,
  selector: 'norby-file-upload',
  templateUrl: './norby-file-upload.component.html',
  styleUrls: ['./norby-file-upload.component.less']
})
export class NorbyFileUploadComponent implements OnChanges {
  @Input() label?: string;
  @Input() infoTooltip?: string;
  @Input() isRequired?: boolean = false;
  @Input() isDisabled?: boolean = false;
  @Input() images?: IImage[] = [];
  @Input() allowMultiple?: boolean = false;
  @Input() acceptedFileTypes?: string = '.jpeg, .jpg, .png';
  @Input() isAltTextDisabled?: boolean = false;

  @Output() onUpdatedImages: EventEmitter<IImage[]> = new EventEmitter<
    IImage[]
  >();

  imagesCopy: IImage[] = [];
  showDialog: boolean = false;
  showDialogIndex: number = 0;

  constructor(private _uploadService: MockFileUploadService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.images) {
      this._initImagesCopy();
    }
  }

  private _initImagesCopy() {
    if (
      this.images?.length &&
      (this.images.length === 1 || !this.allowMultiple)
    ) {
      this.imagesCopy = [this.images[0]];
    } else if (this.images?.length && this.allowMultiple) {
      this.imagesCopy = [...this.images];
    } else {
      this.imagesCopy = [];
    }

    if (this.imagesCopy?.length && this.imagesCopy[0] === null) {
      this.imagesCopy = [];
    }
  }

  handleFilesSelected(event): void {
    const files = (event.target as HTMLInputElement).files;
    const existingFilesCount = this.imagesCopy.length;
    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const image = new IImage();
        if (this.allowMultiple || this.imagesCopy?.length === 0) {
          this.imagesCopy?.push(image);
        } else {
          this.imagesCopy[0] = image;
        }
        this._uploadService.uploadFile(file).then((url) => {
          const image = new IImage();
          image.url = url;
          if (this.allowMultiple) {
            this.imagesCopy[existingFilesCount + i] = image;
          } else {
            this.imagesCopy[0] = image;
          }
          this.onUpdatedImages.emit(this.imagesCopy);
        });
      }
      event.target.value = null;
    }
  }

  handleDeleteImage(image: IImage) {
    const deleteIndex = this.imagesCopy.findIndex((imageCopy) => {
      return imageCopy.url === image.url;
    });
    this.imagesCopy.splice(deleteIndex, 1);

    this.onUpdatedImages.emit(this.imagesCopy);
  }

  handleImageChanged(image: IImage, index) {
    this.imagesCopy[index] = image;
    this.onUpdatedImages.emit(this.imagesCopy);
  }
}
