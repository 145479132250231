import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

import { DeliveryType, Content } from 'models';

import { AuthService } from '../../../services';
import { CaptchaComponent } from 'uikit';

export type EmailOutputType = {
  email: string;
  emailVerificationCodeMedium: DeliveryType | 'none';
};

@Component({
  standalone: false,
  selector: 'lib-content-registration-email',
  templateUrl: './content-registration-email.component.html',
  styleUrls: ['./content-registration-email.component.less']
})
export class ContentRegistrationEmailComponent
  extends CaptchaComponent
  implements OnInit
{
  @Input() email: string;
  @Input() content: Content;
  @Input() emailVerificationCodeMedium: DeliveryType | 'none';

  @Output() handleNext = new EventEmitter<EmailOutputType>();
  @Output() handleSetError = new EventEmitter<any>();
  @Output() handleSkipVerificationCodeStage = new EventEmitter<void>();

  formGroup: UntypedFormGroup;
  isLoading: boolean;
  emailLoginCopy: string;

  constructor(
    private _auth: AuthService,
    private _formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._initEmailLoginCopy();

    this.formGroup = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  private _initEmailLoginCopy() {
    this.emailLoginCopy = 'Please enter your email.';

    if (this.content?.isEvent) {
      this.emailLoginCopy = `${this.emailLoginCopy} You'll only receive messages about this event and can opt out at any time.`;
    } else if (this.content?.isSignup) {
      this.emailLoginCopy = `${this.emailLoginCopy} You can opt out at any time.`;
    }
  }

  async submitEmail() {
    if (!this.formGroup.valid) return;
    this.isLoading = true;
    this.email = this.formGroup.value.email;
    const captchaResponse = await this.executeCaptcha();

    // Initiate the signin flow with transformed number
    try {
      const result = await this._auth.initiateEmailSignInOrLinkingFlow({
        email: this.email,
        recaptchaToken: captchaResponse
      });

      // User has already been authorized and we can skip the email verification code process.
      if (result.credential !== null)
        this.handleSkipVerificationCodeStage.emit();
      this.emailVerificationCodeMedium = result.medium;

      this.isLoading = false;
      this.handleNext.emit({
        email: this.email,
        emailVerificationCodeMedium: this.emailVerificationCodeMedium
      });
    } catch (e) {
      this.isLoading = false;
      this.handleSetError.emit(e);
    }
  }
}
