import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';

import { plainToClass } from 'class-transformer';
import { filter, map, tap } from 'rxjs';

import {
  HeaderSingleSendBlock,
  DEFAULT_BLOCK_PADDING,
  IImage,
  ISlug
} from 'models';
import { BaseComponent } from 'uikit';

@Component({
  standalone: false,
  selector: 'edit-email-header-block',
  templateUrl: './edit-email-header-block.component.html',
  styleUrls: ['./edit-email-header-block.component.less']
})
export class EditEmailHeaderBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() block: HeaderSingleSendBlock;
  @Input() slug: ISlug;

  @Output() onUpdatedBlock: EventEmitter<HeaderSingleSendBlock> =
    new EventEmitter<HeaderSingleSendBlock>();
  formGroup: UntypedFormGroup;

  private _cachedBlockHash: number;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  get blockImageArray(): IImage[] {
    return this.block.image && Object.keys(this.block.image).length > 0
      ? [this.block?.image]
      : [];
  }

  get clickThroughUrlDefault(): string {
    // TODO replace with `https://${this.slug?.defaultDomain}` pending change to nor.by
    return `https://${this.slug?.slug}.nor.by`;
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (this._cachedBlockHash !== this.block.hash) {
      this.formGroup?.patchValue(
        {
          image: this.block?.image,
          height: this.block?.height,
          clickThroughUrl: this.block?.clickThroughUrl,
          backgroundColor: this.block?.theme?.card?.backgroundColor,
          paddingTop: this.block?.paddingTop,
          paddingRight: this.block?.paddingRight,
          paddingBottom: this.block?.paddingBottom,
          paddingLeft: this.block?.paddingLeft
        },
        { emitEvent: false }
      );
    }
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      image: [this.block.image ?? null],
      height: [this.block.height ?? null],
      clickThroughUrl: [this.block.clickThroughUrl ?? ''],
      backgroundColor: [this.block.theme?.card?.backgroundColor],
      paddingTop: [
        this.block?.paddingTop ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingRight: [
        this.block?.paddingRight ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingBottom: [
        this.block?.paddingBottom ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingLeft: [
        this.block?.paddingLeft ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) =>
          plainToClass(HeaderSingleSendBlock, {
            ...this.block,
            ...value,
            ...(value.backgroundColor && {
              theme: { card: { backgroundColor: value.backgroundColor } }
            })
          })
        ),
        tap((block) => (this._cachedBlockHash = block.hash)),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }

  handleBackgroundImageUpdated(image: IImage[]) {
    this.formGroup.patchValue({
      image: image[0]
    });
  }
}
