import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Optional,
  Output,
  Self
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FORM_ERROR_PROVIDER } from 'models';

@Component({
  standalone: false,
  selector: 'norby-input-base',
  templateUrl: './norby-input-base.component.html',
  styleUrls: ['./norby-input-base.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NorbyInputBaseComponent
  implements ControlValueAccessor, OnChanges
{
  @Input() value?: string;
  @Input() placeholder?: string;
  @Input() maxLength?: number;
  @Input() type: 'text' | 'number' | 'email' | 'tel' = 'text';
  @Input() isDisabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() isJoinedRight: boolean = false;
  @Input() isJoinedLeft: boolean = false;

  @Output() onInput = new EventEmitter<string>();

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};

  touched = false;
  val: string;
  errorText: string = '';

  get hasErrors(): boolean {
    return !!this.errorText;
  }

  constructor(
    @Optional() @Self() private _ngControl: NgControl,
    @Optional() @Inject(FORM_ERROR_PROVIDER) private _errorFactory,
    private _cdr: ChangeDetectorRef
  ) {
    if (_ngControl) {
      _ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(): void {
    this.writeValue(this.value);
  }

  ngAfterViewInit(): void {
    if (
      this._ngControl &&
      this._ngControl.value &&
      this._ngControl.value !== this.val
    ) {
      this.writeValue(this._ngControl.value);
    }
  }

  writeValue(value: string) {
    this.val = value;
    this._updateErrors();
    this._cdr.detectChanges();
  }

  handleInput(value: string) {
    this.val = value;
    this._onChanged(value);
    this._markAsTouched();
    this._updateErrors();
    this._cdr.detectChanges();
    this.onInput.emit(value);
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _updateErrors() {
    const controlErrors = this._ngControl?.errors ?? {};
    const errorKeys = Object.keys(controlErrors);
    if (errorKeys.length && this.touched) {
      const errorKey = errorKeys[0];
      const getErrorMessage = this._errorFactory?.[errorKey];
      this.errorText = getErrorMessage?.(controlErrors[errorKey]) ?? 'Error';
    } else if (this.hasErrors) {
      this.errorText = '';
    }
  }

  private _markAsTouched() {
    if (!this.touched) {
      this.touched = true;
      this._onTouched();
    }
  }
}
