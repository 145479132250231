import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { isPlatformBrowser } from '@angular/common';

import { filter, fromEvent, tap } from 'rxjs';

import { DropdownComponent } from '../root-components/dropdown/dropdown.component';
import { THEME_CLASSES, POSITION_MAP } from '../constants';
import { BaseComponent } from '../models/base-component';
import { SubmenuComponent } from '../root-components/submenu';

@Directive({ standalone: false, selector: '[rootDropdown]' })
export class DropdownDirective
  extends BaseComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input('rootDropdown') rootDropdownMenu: DropdownComponent | SubmenuComponent;
  @Input() position: 'center' | 'right';
  @Input() listener?: EventEmitter<MouseEvent>;
  @Output() readonly visibleChange: EventEmitter<boolean> = new EventEmitter();
  private _overlayRef: OverlayRef;
  private _mouseOnDropdown: boolean = false;
  private _mouseOnHost: boolean = false;
  private _hasListeners: boolean = false;
  private _hasButtonPressed: boolean = false;
  private _keepParentMenuOpen: boolean = false;
  private _dropdownInstance: TemplatePortal<any>;
  private _dropdownEnterListener: () => void;
  private _dropdownLeaveListener: () => void;
  private _triggerWidth: number;

  constructor(
    @Inject(THEME_CLASSES) private _themeClasses,
    @Inject(PLATFORM_ID) private _platform,
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
    private _viewContainerRef: ViewContainerRef,
    private _r2: Renderer2
  ) {
    super();
  }

  getPosition(): any[] {
    switch (this.position) {
      case 'center':
        return [
          POSITION_MAP.bottomExtendRight,
          POSITION_MAP.topExtendRight,
          POSITION_MAP.bottomExtendLeft,
          POSITION_MAP.topExtendLeft
        ];
      case 'right':
        return [
          POSITION_MAP.rightLower,
          POSITION_MAP.rightUpper,
          POSITION_MAP.leftLower,
          POSITION_MAP.leftUpper
        ];
      default:
        return [POSITION_MAP.bottomMiddle, POSITION_MAP.topMiddle];
    }
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this._platform)) {
      return;
    }

    setTimeout(() => {
      this._triggerWidth =
        this._elementRef.nativeElement.getBoundingClientRect().width;
      const positionStrategy = this._overlayPositionBuilder
        .flexibleConnectedTo(this._elementRef)
        .withPositions(this.getPosition());

      this._overlayRef = this._overlay.create({
        positionStrategy,
        minWidth: this._triggerWidth,
        scrollStrategy: this._overlay.scrollStrategies.reposition()
      });
      this._themeClasses.forEach((elem: string) => {
        this._overlayRef.addPanelClass(`${elem}`);
      });

      this._dropdownInstance = new TemplatePortal(
        this.rootDropdownMenu?.templateRef,
        this._viewContainerRef
      );
      //clicking current dropdown
      fromEvent(this._overlayRef.overlayElement, 'click')
        .pipe(
          filter((e) => !this._hasButtonPressed),
          tap((e) => (this._hasButtonPressed = true)),
          this.takeUntilDestroy
        )
        .subscribe((e) => {
          this._overlayRef.detach();
          this._hasButtonPressed = false;
          this._mouseOnDropdown = false;
          this._mouseOnHost = false;
        });
    });
  }

  ngOnChanges() {
    super.ngOnChanges();

    //submenu mouse actions
    this.listener
      ?.pipe(this.takeUntilChanges)
      ?.subscribe((event: MouseEvent) => {
        if (event.type === 'mouseenter') {
          this._keepParentMenuOpen = true;
        } else if (event.type === 'mouseleave' || event.type === 'click') {
          this._keepParentMenuOpen = false;
          this.hide();
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._dropdownEnterListener) {
      this._dropdownEnterListener();
    }
    if (this._dropdownLeaveListener) {
      this._dropdownLeaveListener();
    }
  }

  @HostListener('mouseenter')
  show() {
    this._mouseOnHost = true;
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      this._overlayRef.attach(this._dropdownInstance);
      this.visibleChange.emit(true);

      //dropdown menu actions
      if (!this._hasListeners) {
        this._hasListeners = true;
        this._dropdownLeaveListener = this._r2.listen(
          this._overlayRef.overlayElement,
          'mouseleave',
          () => {
            this._mouseOnDropdown = false;
            if (!this._mouseOnHost) {
              this.hide();
            }
          }
        );

        this._dropdownEnterListener = this._r2.listen(
          this._overlayRef.overlayElement,
          'mouseenter',
          () => {
            this._mouseOnDropdown = true;
          }
        );
      }
    }
  }

  @HostListener('mouseleave')
  hide() {
    this._mouseOnHost = false;
    setTimeout(() => {
      if (
        !this._mouseOnDropdown &&
        !this._mouseOnHost &&
        !this._keepParentMenuOpen
      ) {
        this._overlayRef.detach();
        this.visibleChange.emit(false);
      }
    }, 300);
  }
}
