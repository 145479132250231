import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import {
  getDownloadURL,
  ref,
  Storage,
  uploadBytes
} from '@angular/fire/storage';

// 3rd party
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

// Libs
import {
  ACCEPTED_IMAGE_MIME_TYPES_STR,
  IUserMetadata,
  IUserPublicMetadata,
  uuidv4
} from 'models';
import {
  IconService,
  rootLoader,
  rootPlus,
  ErrorService,
  CaptchaComponent
} from 'uikit';
import { AuthService, DrawerService, UserService } from '../../services';

@Component({
  standalone: false,
  selector: 'lib-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.less']
})
export class EditProfileComponent extends CaptchaComponent implements OnInit {
  userProfile: IUserPublicMetadata;
  userMetadata: IUserMetadata;
  profileFormGroup: UntypedFormGroup;
  error = '';
  isUploading = false;
  isLoading = true;
  imagePath: string; // Path of the successfully uploaded image

  readonly PHONE_PLACEHOLDER = '(123) 456-7890';
  readonly IMAGE_MIME_TYPES = ACCEPTED_IMAGE_MIME_TYPES_STR;

  constructor(
    @Inject(NZ_MODAL_DATA) private _modalData,
    private _cdr: ChangeDetectorRef,
    private _formBuilder: UntypedFormBuilder,
    private _user: UserService,
    private _storage: Storage,
    private _error: ErrorService,
    private _dialogRef: NzModalRef<EditProfileComponent>,
    private _auth: AuthService,
    private _iconService: IconService,
    private _drawer: DrawerService
  ) {
    super();
    this._iconService.registerIcons([rootLoader, rootPlus]);
    this.userProfile = this._modalData.userProfile;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._initFormGroup();
    this._initStateListener();
    this._initProfileValues();
    this._initOkayButton();
  }

  private _initFormGroup() {
    this.profileFormGroup = this._formBuilder.group({
      email: [
        this.userMetadata?.email,
        [Validators.required, Validators.email]
      ],
      displayName: [this.userProfile?.displayName, [Validators.required]]
    });
  }

  private _initOkayButton() {
    this._dialogRef.updateConfig({
      nzOnOk: () => {
        this._dialogRef.updateConfig({ nzOkLoading: true });
        this._submitProfile()
          .then(() => this._dialogRef.close())
          .catch((e) => this._setError(e));
      }
    });
  }

  private _initStateListener() {
    this.profileFormGroup.valueChanges
      .pipe(this.takeUntilDestroy)
      .subscribe(() => {
        this._dialogRef.updateConfig({
          nzOkDisabled: !this.profileFormGroup.valid
        });
      });
  }

  private _initProfileValues() {
    forkJoin([
      this._user.currentUserProfile$().pipe(take(1)),
      this._user.currentUserMetadata$().pipe(take(1))
    ]).subscribe(([userProfile, userMetadata]) => {
      this.userProfile = userProfile;
      this.userMetadata = userMetadata;
      this.isLoading = false;

      this.profileFormGroup.patchValue({
        email: this.userMetadata?.email,
        displayName: this.userProfile?.displayName
      });
    });
  }

  get currentImageUrl(): string {
    return this.imagePath || this.userProfile?.photoURL;
  }

  // Called by file picker event emitter in UI
  uploadFileFn = (file: NzUploadFile): boolean => {
    if (!file) return false;
    const extension = file.name?.split('.')?.pop();
    const fileName = `${uuidv4()}.${extension}`;
    const storageRef = ref(this._storage, `images/${fileName}`);
    this.isUploading = true;

    uploadBytes(storageRef, file as any)
      .then(async (snapshot) => {
        const url = await getDownloadURL(snapshot.ref);
        this.isUploading = false;
        this.imagePath = url;
      })
      .catch((e) => {
        this.isUploading = false;
      });

    return false;
  };

  // Submit user info step
  private async _submitProfile() {
    if (!this.profileFormGroup.valid) return;
    const values = this.profileFormGroup.value;
    const displayName = values?.displayName;
    const photoURL = this.imagePath;
    const email = values?.email;
    const promises: Promise<any>[] = [
      this._user.setUserProfile({
        displayName,
        ...(photoURL && { photoURL })
      })
    ];

    const verifiedEmails = [
      ...(this.userMetadata?.verifiedEmails ?? []),
      ...(this.userMetadata?.email && this.userMetadata?.emailVerified
        ? [this.userMetadata.email]
        : [])
    ];

    if (email && verifiedEmails.includes(email)) {
      // If the email address is a federated identifier. We promote
      // it to the primary.
      promises.push(this._auth.promoteSecondOrderEmail({ email }));
    } else if (email) {
      // Go through auth flow.
      const captchaResponse = await this.executeCaptcha();

      promises.push(
        this._auth.initiateEmailSignInOrLinkingFlow({
          email,
          recaptchaToken: captchaResponse,
          sendMagicLink: true // Send magic link instead of verification code
        })
      );
    }

    return Promise.all(promises);
  }

  async handleAddPhoneNumber(): Promise<void> {
    const response = await this._drawer.launchAddUserPhoneNumberDialog();
    if (response) {
      this.userMetadata.phoneNumber = response;
    }
  }

  private _setError(error: any) {
    this.error = this._error.formatError(error);
    this._dialogRef.updateConfig({ nzOkLoading: false });
    this._cdr.detectChanges();
  }
}
