import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  standalone: false,
  selector: 'root-card-meta',
  templateUrl: './card-meta.component.html',
  styleUrls: ['./card-meta.component.less']
})
export class CardMetaComponent {
  @Input() title: string | TemplateRef<void> | null = null;
  @Input() description: string | TemplateRef<void> | null = null;
  @Input() avatar: TemplateRef<void> | null = null;

  constructor() {}
}
