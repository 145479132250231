import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

// Lib
import {
  ISlug,
  SOCIAL_TYPES,
  SocialIconsSettings,
  VisibleLink,
  buildSocialLinksObjectFromBlock
} from 'models';
import {
  BaseComponent,
  IconService,
  NorbyInputComponent,
  rootMoreVertical
} from 'uikit';

@Component({
  standalone: false,
  selector: 'norby-edit-social-icons',
  templateUrl: './norby-edit-social-icons.component.html',
  styleUrls: ['./norby-edit-social-icons.component.less']
})
export class NorbyEditSocialIconsComponent
  extends BaseComponent
  implements OnChanges, OnInit, OnDestroy
{
  @ViewChildren(NorbyInputComponent)
  inputElements!: QueryList<NorbyInputComponent>;
  @ViewChild('dropList') dropList: ElementRef;

  @Input() socialLinks: SocialIconsSettings[];
  @Input() slug: ISlug;
  @Input() dragDisabled = false;
  @Output() onUpdatedLinks: EventEmitter<SocialIconsSettings[]> =
    new EventEmitter<SocialIconsSettings[]>();

  links: Array<VisibleLink>;

  private _currentEditingIndex = -1;

  constructor(
    private _iconService: IconService,
    private _scrollDispatcher: ScrollDispatcher
  ) {
    super();
    this._iconService.registerIcons([rootMoreVertical]);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.socialLinks) {
      this.links =
        this.socialLinks?.map?.((setting) =>
          buildSocialLinksObjectFromBlock(setting)
        ) || [];

      if (this._currentEditingIndex > -1) {
        setTimeout(() => {
          this.inputElements.last?.focus();
        });
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  isEditing(index: number): boolean {
    return (
      this._currentEditingIndex > -1 && this._currentEditingIndex === index
    );
  }

  handleDrop(event: CdkDragDrop<VisibleLink[]>) {
    const currIdx = event.currentIndex;
    const prevIdx = event.previousIndex;

    if (currIdx === prevIdx) {
      return;
    }

    const socialLinks = this.socialLinks.slice();
    moveItemInArray(socialLinks, prevIdx, currIdx);
    this.onUpdatedLinks.emit(socialLinks);
  }

  handleLinkSelectorValueChanged(key: SOCIAL_TYPES) {
    const socialLinks =
      this._currentEditingIndex > -1
        ? this._getBlockWithUpdatedIndex(this._currentEditingIndex)
        : this.socialLinks;

    const initialUrl = this.slug?.accountInfo?.links?.[key] || '';

    this._currentEditingIndex = this.socialLinks?.length || 0;
    this.onUpdatedLinks.emit([...(socialLinks || []), { [key]: initialUrl }]);
  }

  handleSaveSocialLink(index: number) {
    const socialLinks = this._getBlockWithUpdatedIndex(index);
    this.onUpdatedLinks.emit(socialLinks);
    this._currentEditingIndex = -1;
  }

  handleEditSocialLink(index: number) {
    if (this._currentEditingIndex > -1) {
      const socialLinks = this._getBlockWithUpdatedIndex(
        this._currentEditingIndex
      );
      this.onUpdatedLinks.emit(socialLinks);
    }

    this._currentEditingIndex = index;
    setTimeout(() => {
      this.inputElements.last?.focus();
    });
  }

  handleRemoveSocialLink(index: number) {
    if (index > -1) {
      const copiedLinks = [...this.socialLinks];
      copiedLinks.splice(index, 1);
      this.onUpdatedLinks.emit(copiedLinks);
    }

    this._currentEditingIndex = -1;
  }

  private _getBlockWithUpdatedIndex(index: number) {
    const { key, url } = this.links[index];
    const oldSocialLinks = this.socialLinks;
    const settingsV2 = [
      ...oldSocialLinks.slice(0, this._currentEditingIndex),
      { [key]: url },
      ...oldSocialLinks.slice(this._currentEditingIndex + 1)
    ];

    return settingsV2;
  }
}
