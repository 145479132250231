import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';

import dayjs from 'dayjs';
import { filter } from 'rxjs';
import { BaseComponent } from '../../../models/base-component';

@Component({
  standalone: false,
  selector: 'norby-date-time-picker',
  templateUrl: './norby-date-time-picker.component.html',
  styleUrls: ['./norby-date-time-picker.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyDateTimePickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NorbyDateTimePickerComponent),
      multi: true
    }
  ]
})
export class NorbyDateTimePickerComponent
  extends BaseComponent
  implements ControlValueAccessor, Validator, OnInit
{
  @Input() label?: string;
  @Input() infoTooltip?: string;
  @Input() dateFormat?: string = 'MMM D YYYY';
  @Input() errorText?: string;
  @Input() helperText?: string;
  @Input() isDisabled?: boolean = false;

  dtFormGroup: UntypedFormGroup;

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  private _initForm() {
    this.dtFormGroup = this._formBuilder.group({
      date: ['', Validators.required],
      time: ['', Validators.required]
    });

    this.dtFormGroup.valueChanges
      .pipe(
        filter(() => this.dtFormGroup.valid),
        this.takeUntilDestroy
      )
      .subscribe((val) => {
        this._onChanged(new Date(val.date + ' ' + val.time));
        this._markAsTouched();
      });
  }

  writeValue(value: Date) {
    this.dtFormGroup.patchValue(
      {
        date: value ? dayjs(value).format(this.dateFormat) : '',
        time: value ? dayjs(value).format('h:mm a') : ''
      },
      { emitEvent: false }
    );
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    const { date, time } = this.dtFormGroup.value;
    return (!date && !time) || this.dtFormGroup.valid
      ? null
      : {
          invalidForm: { valid: false, message: 'Invalid date' }
        };
  }
}
