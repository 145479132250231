import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseComponent } from '../../../models/base-component';
import { NorbyRadioButtonComponent } from '../norby-radio-button';

@Component({
  standalone: false,
  selector: 'norby-radio-button-group',
  templateUrl: './norby-radio-button-group.component.html',
  styleUrls: ['./norby-radio-button-group.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyRadioButtonGroupComponent),
      multi: true
    }
  ]
})
export class NorbyRadioButtonGroupComponent
  extends BaseComponent
  implements ControlValueAccessor, AfterContentInit
{
  @Input() label: string;
  @Input() isDisabled?: boolean = false;
  @Input() block?: boolean = false;
  @Input() size: 'default' | 'large' | 'small' = 'default';
  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  @ContentChildren(NorbyRadioButtonComponent) buttons:
    | QueryList<NorbyRadioButtonComponent>
    | undefined;

  value: string;

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  constructor() {
    super();
  }

  ngAfterContentInit(): void {
    if (this.buttons) {
      let buttonsArray = this.buttons.toArray();
      for (let i = 0; i < buttonsArray.length; i++) {
        buttonsArray[i].isFirst = i === 0;
        buttonsArray[i].isLast = i === buttonsArray.length - 1;
        buttonsArray[i].nextButtonValue = buttonsArray[i + 1]?.buttonValue;
        buttonsArray[i].size = this.size;
        buttonsArray[i].selectedValue = this.value;
        if (!buttonsArray[i].isDisabled) {
          buttonsArray[i].isDisabled = this.isDisabled;
        }
        buttonsArray[i].buttonClicked
          .pipe(this.takeUntilDestroy)
          .subscribe((buttonValue) => {
            this._updateVal(buttonValue);
            this.buttonClicked.emit(buttonValue);
            this._onChanged(buttonValue);
            this._markAsTouched();
          });
      }
    }
  }

  private _updateVal(value: string) {
    this.value = value;
    if (this.buttons && this.value) {
      this.buttons.forEach((button) => {
        button.selectedValue = this.value;
      });
    }
  }

  writeValue(value: string) {
    this._updateVal(value);
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }
}
