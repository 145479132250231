import { Component, Input, OnInit, AfterViewInit } from '@angular/core';

// Libs
import { IPSQLContact, PAGE_SIZE } from 'models';
import {
  BaseComponent,
  IconService,
  rootMail,
  rootCheckSquare,
  rootTag,
  rootCheckCircle,
  rootCheck
} from 'uikit';

// 3rd party
import { plainToClass } from 'class-transformer';
import {
  ContactModalService,
  ContactsService,
  DeviceService
} from '../../services';

@Component({
  standalone: false,
  selector: 'app-contact-table',
  templateUrl: './contact-table.component.html',
  styleUrls: ['./contact-table.component.less']
})
export class ContactTableComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Input() userCanEdit = false;
  @Input() totalItems = 0;

  currentPage = 1;
  pageSize = PAGE_SIZE;
  contacts: Array<IPSQLContact> = [null, null, null, null, null];
  isLoading = true;
  isMobile: boolean;

  constructor(
    private _contactsService: ContactsService,
    private _contactModal: ContactModalService,
    private _iconService: IconService,
    private _device: DeviceService
  ) {
    super();
    this._iconService.registerIcons([
      rootMail,
      rootCheckSquare,
      rootTag,
      rootCheckCircle,
      rootCheck
    ]);
  }

  ngAfterViewInit(): void {
    this._initResizeObservers();
  }

  ngOnInit() {
    this._fetchContacts({ offset: 0 });
  }

  handlePageChange(page: number) {
    const offset = this.pageSize * (page - 1);
    this._fetchContacts({ offset });
  }

  handlePageSizeChange(size: number) {
    this.pageSize = size ?? this.pageSize;
    this.currentPage = 1;
    const offset = this.pageSize * (this.currentPage - 1);
    this._fetchContacts({ offset });
  }

  trackBy(idx: number, item: IPSQLContact) {
    return item?.id;
  }

  async showContactDetails(contact: IPSQLContact, index: number) {
    const updatedContact =
      await this._contactModal.launchContactDetailsDrawer(contact);

    if (updatedContact.shouldDelete) {
      this.contacts.splice(index, 1);
      return;
    }

    if (updatedContact?.contact) {
      this.contacts[index] = updatedContact.contact;
    }
  }

  private _initResizeObservers() {
    this._device.isMobile$.pipe(this.takeUntilDestroy).subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  private _fetchContacts({ offset }: { offset?: number }) {
    this.isLoading = true;
    this._contactsService
      .getContacts({
        offset,
        lite: true,
        limit: this.pageSize,
        requireMedium: true
      })
      .then(
        (res) =>
          (this.contacts =
            res?.edges?.map((edge) => plainToClass(IPSQLContact, edge?.node)) ??
            [])
      )
      .finally(() => (this.isLoading = false));
  }
}
