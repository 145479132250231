import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({ standalone: false, selector: '[carousel-content]' })
export class CarouselContentDirective {
  readonly el: HTMLElement;

  set isActive(value: boolean) {
    this._active = value;
    if (this.isActive) {
      this.renderer.addClass(this.el, 'slick-active');
      this.renderer.addClass(this.el, 'pointer-events-auto');
    } else {
      this.renderer.removeClass(this.el, 'slick-active');
      this.renderer.removeClass(this.el, 'pointer-events-auto');
    }
  }

  get isActive(): boolean {
    return this._active;
  }

  private _active = false;

  constructor(
    elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.el = elementRef.nativeElement;
    this.renderer.addClass(elementRef.nativeElement, 'float-left');
    this.renderer.addClass(elementRef.nativeElement, 'block');
  }
}
