import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

import { IImage, IResponsiveImage, NewsletterSignupPageBlock } from 'models';
import { CaptchaComponent } from '../../models';
import { ErrorService, MockContentInteractionsService } from '../../services';

@Component({
  standalone: false,
  selector: 'lib-newsletter-signup-view',
  templateUrl: './newsletter-signup-view.component.html',
  styleUrls: ['./newsletter-signup-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsletterSignupViewComponent
  extends CaptchaComponent
  implements OnInit
{
  @Input() block: NewsletterSignupPageBlock;
  @Input() value: string;

  formGroup: UntypedFormGroup;
  isLoading = false;
  subscribed = false;
  defaultPostSubmitTitle = 'Thanks!';

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _interactions: MockContentInteractionsService,
    private _error: ErrorService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  get images(): IImage[] {
    return this.block?.images || [];
  }

  get responsiveImages(): IResponsiveImage[] {
    return this.block?.responsiveImages || [];
  }

  get tags(): string[] {
    return this.block?.tags || [];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.formGroup = this._formBuilder.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  async submit() {
    this.isLoading = true;
    const { email } = this.formGroup.value;
    this._cdr.detectChanges();
    const captchaResponse = await this.executeCaptcha();

    try {
      await this._interactions.subscribeToNewsletter(
        email,
        captchaResponse,
        this.tags
      );
      this.subscribed = true;
    } catch (e) {
      this._error.displayError(e);
    }

    this.isLoading = false;
    this._cdr.detectChanges();
  }
}
